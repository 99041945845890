import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Header from "../../../components/header/header";
import Attribute from "../../../components/setting-admin/attribute/attribute";
import TelegramLink from "../../../components/setting-admin/telegram-link/telegram-link";
import Time from "../../../components/setting-admin/time/time";
import Sidebar from "../../../components/sidebar/sidebar";
import { settingsGet } from "../../../store/admin/setting/settingGetSlice/settingGetSlice";
import { settingPostUpdate } from "../../../store/admin/setting/settingPostSlice/settingPostSlice";

function AdminSetting() {
  const [key, setKey] = useState("setting-tab-1");
  const handleSelect = (k) => {
    setKey(k);
  };
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.settingAdmin);

  const { isLoading } = useSelector((state) => state.settingUpdate);
  useEffect(() => {
    dispatch(settingsGet());
  }, []);

  const onSubmit = (e, formData) => {
    e.preventDefault();
    dispatch(settingPostUpdate(formData)).then((res) => {
      if (res.payload.status === 200) {
        toast.success("Успешно сохранено");
      }
    });
  };

  const renderContent = () => {
    switch (key) {
      case "setting-tab-1":
        return (
          <Attribute data={data} onSubmit={onSubmit} isLoading={isLoading} />
        );
      case "setting-tab-2":
        return <Time data={data} onSubmit={onSubmit} isLoading={isLoading} />;
      case "setting-tab-3":
        return (
          <TelegramLink data={data} onSubmit={onSubmit} isLoading={isLoading} />
        );
      default:
        return null;
    }
  };

  return (
    <Sidebar>
      <div className="main__data">
        <Header title="Настройки" />
        {/* {isLoading ? (
          <Preloader />
        ) : ( */}
        <div className="settings">
          <Tabs activeKey={key} onSelect={handleSelect}>
            <Tab eventKey="setting-tab-1" title="Атрибуты" />
            <Tab eventKey="setting-tab-2" title="Время" />
            <Tab eventKey="setting-tab-3" title="Телеграм" />
          </Tabs>
          <div className="tab-content">{renderContent()}</div>
        </div>
        {/* )} */}
      </div>
    </Sidebar>
  );
}

export default AdminSetting;
