import React from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";

function Buttons({ garage, item, checkInStock, setShow, setSingle, setId }) {
  const { user } = useSelector((state) => state.profileGet);
  const showPhoneForUser = () => {
    swal({
      title: "Контакты продавца: ",
      text: `${item?.courier_fullname} : ${item?.courier_phone} `,
    });
  };
  const renderButtons = () => {
    if (user?.data?.role === "User") {
      return (
        <button
          type="button"
          className="card__item-btn blue-outline-btn  mt-3"
          onClick={showPhoneForUser}  
        >
          Получить контакты
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="card__item-btn blue-outline-btn  mt-3"
          onClick={
            item?.show_hide_btn?.name === "Получить контакты" ||
            item?.status.code === "storage"
              ? () => {
                  setSingle(item);
                  setShow(true);
                }
              : () => checkInStock(item.id)
          }
        >
          {item?.show_hide_btn?.name === "Получить контакты" ||
          item?.status.code === "storage"
            ? "Выбрать действие"
            : item?.show_hide_btn?.name}
        </button>
      );
    }
  };

  return <>{!garage && renderButtons()}</>;
}

export default Buttons;
