import React from "react";

const SkeletonLoaderChatCreate = ({
  height = "20px",
  width = "100%",
  borderRadius = "4px",
  padding = "15px",
  margin = "15px",
}) => {
  return (
    <div
      className="skeleton-loader"
      style={{
        height,
        width,
        borderRadius,
        padding,
        margin,
      }}
    ></div>
  );
};

export default SkeletonLoaderChatCreate;
