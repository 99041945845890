import React from "react";
import { useSelector } from "react-redux";
import app from "../../../../images/app.svg";
import lock from "../../../../images/lock.svg";
import price from "../../../../images/price.svg";
import pro_icon from "../../../../images/pro_icon.svg";
import sitecase from "../../../../images/sitecase.svg";
import tag2 from "../../../../images/tag2.svg";
import time from "../../../../images/time.svg";

function ProBaner() {
  const { user } = useSelector((state) => state.profileGet);
  const role = user?.data?.role;
  console.log(role);

  return (
    <div className="side-bar-banner profile-pro-baner">
      <div class="step">
        <div class={`number ${role !== "Pro" ? "" : "pro-number"}`}>
          {role !== "Pro" ? 1 : <img src={pro_icon} alt="" />}
        </div>

        <div class="content w-100">
          <h2 className="pro__title">
            {role !== "Pro" ? (
              <>
                Получите статус <b>PRO</b>
                <span>и откройте больше возможностей</span>
              </>
            ) : (
              <>
                У вас статус <b>PRO!</b>
              </>
            )}
          </h2>

          <div className="profile-pro-baner__list">
            <div>
              <img src={app} alt="" />
              Больше предложений
            </div>
            <div>
              <img src={tag2} alt="" />
              Персональные скидки
            </div>
            <div>
              <img src={time} alt="" />
              Ранний доступ
            </div>
            {role !== "Pro" && (
              <a
                href="https://forms.yandex.ru/cloud/66b1fea3068ff0b75ad7dad2/"
                data-text={role !== "Pro" ? "Стать PRO" : "Продлить "}
                target="_blank"
              >
                {role !== "Pro" ? "Стать PRO" : "Продлить "}
              </a>
            )}
          </div>
        </div>
      </div>
      {role !== "Pro" && (
        <div class="step">
          <div class="number endpoint">2</div>
          <div class="content w-100">
            <h2 className="pro__title">
              Внесите депозит
              <span>и зарабатывайте по максимуму</span>
            </h2>
            <div className="profile-pro-baner__list">
              <div>
                <img src={sitecase} alt="" />
                Доступ к аукциона
              </div>
              <div>
                <img src={price} alt="" />
                Согласование стоимости
              </div>
              <div>
                <img src={lock} alt="" />
                Резервирование
              </div>
              <a href="#" className="deposit__inactive--button">
                Необходим статус PRO
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProBaner;
