import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { register } from "../../../store/auth/register/registerSlice";
import { resetPasswordVerifyPost } from "../../../store/auth/reset-password/resetPasswordVerifySlice";

function Timer({ seconds, phoneNumber, repeatSms }) {
  const initialTime = seconds; // 3 minutes in seconds
  const [time, setTime] = useState(initialTime);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      } else {
        clearInterval(timer);
        // Timer has reached 0, you can trigger an action here.
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timer);
  }, [time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const onRepeatSms = () => {
    dispatch(
      repeatSms === "register"
        ? register({ phone: phoneNumber })
        : resetPasswordVerifyPost({ phone: phoneNumber })
    )
      .then((response) => {
        if (response.payload.status == 200) {
          toast.success("Мы отправили SMS с кодом подтверждения на ваш номер");
        } else {
          toast.error("Произошла ошибка!");
        }
      })
      .catch((error) => {
        toast.error("Произошла ошибка!");
      });
  };

  return (
    <>
      {time > 0 ? (
        <>Повторный смс через {formatTime(time)}</>
      ) : (
        <span className="auth__text cursor-pointer" onClick={onRepeatSms}>
          Повторный смс
        </span>
      )}
    </>
  );
}

export default Timer;
