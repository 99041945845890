import axios from "axios";

const API_URL = "/v1/admin/user";

// Получить данные всех пользователей
const usersGet = async (query) => {
  const response = await axios.get(API_URL, { params: query });
  return { data: response.data, status: response.status };
};
//

// Получить данные для создание пользователй
const usersCreateGet = async () => {
  const response = await axios.get(API_URL + "/create");
  return { data: response.data, status: response.status };
};
//

// Создать пользователя
const userStore = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//

// Получить данные для редактирование пользователй
const userEdit = async (id) => {
  const response = await axios.get(API_URL + "/edit/" + id);
  return { data: response.data, status: response.status };
};
//

// Удаление  пользователя
const userDelete = async (id) => {
  const response = await axios.delete(API_URL + "/" + id);
  return { data: response.data, status: response.status };
};
//

// Обновление  пользователя
const userUpdate = async (id, data) => {
  const response = await axios.post(API_URL + "/" + id, data);
  return { data: response.data, status: response.status };
};
//

// Отправка пуша одному пользователю
const userPush = async (id, data) => {
  const response = await axios.post(API_URL + "/notification/" + id, data);
  return { data: response.data, status: response.status };
};
//

// Отправка пуша одному пользователю
const userSearch = async (data) => {
  const response = await axios.post(API_URL + "/search", data);
  return { data: response.data, status: response.status };
};
//

const usersService = {
  usersGet,
  usersCreateGet,
  userStore,
  userEdit,
  userDelete,
  userUpdate,
  userPush,
  userSearch,
};

export default usersService;
