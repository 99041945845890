import { Route, Routes, useNavigate } from "react-router-dom";

import axios from "axios";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import InsufficientRights from "../../pages/403/403";
import NotFoundPage from "../../pages/404/404";
import ApplicationDetail from "../../pages/applications/application-details/application-details";
import Applications from "../../pages/applications/applications";
import Garage from "../../pages/garage/garage";
import Login from "../../pages/login/login";
import Lots from "../../pages/lots/lots";
import LotsDetails from "../../pages/lots/lots-details/lots-details";
import NotVerification from "../../pages/not-verification/not-verification";
import Profile from "../../pages/profile/profile";
import Rates from "../../pages/rates/rates";
import Register from "../../pages/register/register";
import SellerAutoAdd from "../../pages/seller/seller-auto-add/seller-auto-add";
import SellerAutoEdit from "../../pages/seller/seller-auto-edit/seller-auto-edit";
import SellerAutoList from "../../pages/seller/seller-auto-list/seller-auto-list";
import Setting from "../../pages/settting/setting";
import Storage from "../../pages/storage/storage";
import { setHeaderAdaptiveSlice } from "../../store/header/header-adaptive/headerAdaptiveSlice";
import PrivateRoutes from "../../utils/PrivateRoutes";
import ProRoutes from "../../utils/ProRoutes";
import SiteHeader from "../site-header/site-header";

import AdminApplication from "../../pages/admin/application/admin-application";
import AdminApplicationCreate from "../../pages/admin/application/create/create";
import AdminApplicationEdit from "../../pages/admin/application/edit/edit";
import AdminQrCode from "../../pages/admin/application/qr-code/qr-code";
import AdminSingle from "../../pages/admin/application/single/single";
import AdminCompanies from "../../pages/admin/companies/admin-companies";
import AdminLots from "../../pages/admin/lots/admin-lots";
import AdminLotsSingle from "../../pages/admin/lots/single/single";
import AdminNotifications from "../../pages/admin/notificaions/admin-notifications";
import AdminProfile from "../../pages/admin/profile/admin-profile";
import AdminSetting from "../../pages/admin/setting/admin-setting";
import AdminUsers from "../../pages/admin/users/admin-users";
import AdminCreateUser from "../../pages/admin/users/create/create";
import AdminEditUser from "../../pages/admin/users/edit/edit";
import ApplicationsGuest from "../../pages/applications/guest/guest";
import ApplicationGuestDetail from "../../pages/applications/guest/guest-details";
import AutopodBor from "../../pages/autopodbor/autopodbor";
import ChatIndex from "../../pages/chat/chat-index/chat-index";
import ProductsOur from "../../pages/products-our/products-our";
import Verification from "../../pages/register/verification/verification";
import ResetPasswordVerify from "../../pages/reset-password/reset-password-verify";
import ResetPassword from "../../pages/reset-password/reset-password/reset-password";
import SellerAutoRepeat from "../../pages/seller/seller-auto-repeat/seller-auto-repeat";
import UnderConstruction from "../../pages/under-construction/under-construction";
import { errorPost } from "../../store/error/errorSlice/errorSlice";
import { getBaseUrl } from "../../utils/base-url/base-url";
import Error500 from "../500/500";
import ErrorBoundary from "../error/error-boundary";

axios.defaults.baseURL = getBaseUrl();

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Accept"] = "application/json";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem("web_vinz"));
  config.headers.Authorization = token ? `Bearer ${token.access_token}` : "";
  return config;
});

function App() {
  const { user } = useSelector((state) => state.profileGet);
  const userLocal = JSON.parse(localStorage.getItem("web_vinz"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.code === "ERR_NETWORK") {
        // Если ошибка 401, перенаправляем на страницу входа
        // localStorage.removeItem("web_vinz");
        // navigate("/500");
      }
      if (error.response && error.response.status === 401) {
        // Если ошибка 401, перенаправляем на страницу входа
        localStorage.removeItem("web_vinz");
        // navigate("/login");
      }

      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Устанавливаем глобальный обработчик ошибок
      window.onerror = function (message, source, lineno, colno, error) {
        const errorData = `Глобальная ошибка в новом сайте на React: ${message}
      Source: ${source} 
      Line: ${lineno} 
      Column: ${colno}
      Stack Trace: ${error?.stack || "No stack trace available"}
    `;

        dispatch(errorPost({ error: errorData.trim() }));
      };

      return () => {
        // Удаляем обработчик ошибок при размонтировании компонента
        window.onerror = null;
      };
    }
  }, []);

  if (userLocal) {
    const updatedUserLocal = {
      ...userLocal,
      role: user?.data?.role || userLocal.role,
    };

    if (user?.data?.role !== userLocal.role) {
      localStorage.setItem("web_vinz", JSON.stringify(updatedUserLocal));
    }
  }

  useEffect(() => {
    dispatch(setHeaderAdaptiveSlice(false));
  }, [navigate]);

  return (
    <div className="App">
      <ErrorBoundary>
        <SiteHeader />
        <Routes>
          <Route path="/" element={<ApplicationsGuest />} />
          <Route
            path="/applications/single"
            element={<ApplicationGuestDetail />}
          />

          <Route path="/autopodbor" element={<AutopodBor />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verification" element={<Verification />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route
            path="/reset-password-verify"
            element={<ResetPasswordVerify />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/our_roducts" element={<ProductsOur />} />
          <Route path="/500" element={<Error500 />} />
          {/* <Header /> */}
          <Route
            element={
              <PrivateRoutes
                user={userLocal}
                roles={["Dealer", "Pro", "User"]}
              />
            }
          >
            {/* Application */}
            <Route path="/applications" element={<Applications />} />
            <Route path="/not-verification" element={<NotVerification />} />
            <Route
              path="/applications/:status_id/:id"
              element={<ApplicationDetail />}
            />
            <Route path="/garage" element={<Garage />} />

            <Route element={<ProRoutes />}>
              <Route path="/storage" element={<Storage />} />
              {/* Auction */}
              <Route path="/lots/1" element={<Lots />} />
              <Route path="/lots/:id" element={<Rates />} />
              <Route path="/lots/:status_id/:id" element={<LotsDetails />} />
              <Route path="/rates" element={<Rates />} />
            </Route>
          </Route>
          <Route
            element={
              <PrivateRoutes
                user={userLocal}
                roles={["Dealer", "Pro", "User", "Seller"]}
              />
            }
          >
            <Route path="/setting" element={<Setting />} />
          </Route>
          <Route
            element={
              <PrivateRoutes
                user={userLocal}
                roles={["Dealer", "Pro", "Seller", "Manager", "Admin"]}
              />
            }
          >
            {/* Profile */}
            <Route path="/profile/:id?" element={<Profile />} />
            <Route path="/discussion" element={<ChatIndex />} />
          </Route>
          <Route
            element={
              <PrivateRoutes
                user={userLocal}
                roles={["Seller", "Manager", "ManagerPRO", "Admin"]}
              />
            }
          >
            {/* Seller List */}
            <Route path="/seller/:status_id?" element={<SellerAutoList />} />
            {/* Seller add auto */}
            <Route path="/add-auto" element={<SellerAutoAdd />} />
            {/* Seller edit auto */}
            <Route path="/edit-auto/:id" element={<SellerAutoEdit />} />
            <Route path="/repeat-auto/:id" element={<SellerAutoRepeat />} />
            <Route path="/seller/:status_id/:id" element={<LotsDetails />} />
          </Route>
          <Route
            element={
              <PrivateRoutes
                user={userLocal}
                roles={["Manager", "ManagerPRO", "Admin"]}
              />
            }
          >
            <Route path="/admin">
              {/* Aplications */}
              <Route path="application/:id" element={<AdminApplication />} />
              <Route
                path="application/:status_id/:id"
                element={<AdminSingle />}
              />
              <Route path="lots/:status_id/:id" element={<AdminLotsSingle />} />
              <Route
                path="application/create"
                element={<AdminApplicationCreate />}
              />
              <Route
                path="application/edit/:id"
                element={<AdminApplicationEdit />}
              />
              <Route path="application/qr-code/:id" element={<AdminQrCode />} />
              {/*  */}
              {/* Profile */}
              <Route path="profile" element={<AdminProfile />} />
              {/*  */}
              {/* Users */}
              <Route path="users" element={<AdminUsers />} />
              <Route path="users/create" element={<AdminCreateUser />} />
              <Route path="users/edit/:id" element={<AdminEditUser />} />
              {/*  */}
              {/* Lots */}
              <Route path="lots/:id" element={<AdminLots />} />
              <Route path="lots/create" element={<SellerAutoAdd />} />
              <Route path="lots/edit/:id" element={<SellerAutoEdit />} />
              <Route path="lots/single/:id" element={<LotsDetails />} />
              {/*  */}
              {/* Setting */}
              <Route path="setting" element={<AdminSetting />} />
              {/*  */}
              {/* Insurance companies */}
              <Route path="insurance-companies" element={<AdminCompanies />} />
              {/*  */}
              {/* Notifications */}
              <Route path="notifications" element={<AdminNotifications />} />
              {/*  */}
            </Route>
          </Route>
          {/* <Route path="/password-reset" element={<PasswordReset />} /> */}
          {/* 404 страница */}
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/403" element={<InsufficientRights />} />
          <Route path="/under-construction" element={<UnderConstruction />} />
          {/*  */}
        </Routes>
        <ToastContainer />
        {/* <Footer /> */}
      </ErrorBoundary>
    </div>
  );
}

export default App;
