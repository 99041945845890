import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import usersService from "../usersService";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// City get

export const userUpdate = createAsyncThunk(
  "admin/users/update",
  async ({ id, data }, thunkAPI) => {
    try {
      return await usersService.userUpdate(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const userUpdateSlice = createSlice({
  name: "userUpdate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload;
      })
      .addCase(userUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.users = null;
      });
  },
});

export default userUpdateSlice.reducer;
