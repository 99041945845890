import { createAsyncThunk } from "@reduxjs/toolkit";
import usersService from "../usersService";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// User search

export const userSearch = createAsyncThunk(
  "admin/users/search",
  async (data, thunkAPI) => {
    try {
      return await usersService.userSearch(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// const userSearchSlice = createSlice({
//   name: "userSearch",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(userSearch.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(userSearch.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.isSuccess = true;
//         state.users = action.payload;
//       })
//       .addCase(userSearch.rejected, (state, action) => {
//         state.isLoading = false;
//         state.isError = true;
//         state.message = action.payload;
//         state.users = null;
//       });
//   },
// });

// export default userSearchSlice.reducer;
