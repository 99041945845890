import { createSlice } from "@reduxjs/toolkit";

const headerStyckySlice = createSlice({
  name: "header/sticky",
  initialState: { isSticky: true }, // Храним `isSticky` как часть объекта

  reducers: {
    setHeaderStyckySlice: (state, action) => {
      state.isSticky = action.payload; // Обновляем `isSticky`
    },
  },
});

export const { setHeaderStyckySlice } = headerStyckySlice.actions;
export default headerStyckySlice.reducer;
