import axios from "axios";

const API_URL = "/v1/insurance-companies";

// companies index
const companiesIndex = async (query) => {
  const response = await axios.get(API_URL, {
    params: query,
  });
  return { data: response.data, status: response.status };
};
//

const companiesService = {
  companiesIndex,
};

export default companiesService;
