import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FilterAdminHeader from "../admin/header-filter/header-filter";

function Header({
  title,
  subtitle,
  children,
  market,
  search,
  user_search,
  setSearch,
}) {
  const { user } = useSelector((state) => state.profileGet);

  return (
    <div className="main__data-head">
      {children}
      <div className="main__data-info">
        <div className="main__data-heder-title">{title}</div>
        <div className="main__data-heder-subtitle">{subtitle}</div>
      </div>
      {search && (
        <FilterAdminHeader user={user} market={market} setSearch={setSearch} />
      )}
      {user_search && (
        <div className="d-flex align-items-center admin__header-filter">
          <div className="admin__header-filter-search">
            <span>Поиск пользователя</span>
            <input
              type="text"
              className="input-style"
              placeholder="Все"
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="main__data-head-profile ml-auto">
        {user && Object.keys(user).length !== 0 ? (
          <Link
            to={
              ["Admin", "Manager", "ManagerPRO"].includes(user?.data.role)
                ? "/admin/profile"
                : "/setting"
            }
          >
            <div>
              {user?.data.user[0].name !== ""
                ? user?.data.user[0].name
                : user?.data.user[0].phone}
            </div>
            <div className="main__data-head-profile-role text-right">
              <span> {user?.data.role}</span>
            </div>
          </Link>
        ) : (
          <Link to="/login" type="submit" className="gradient-btn-blue ml-auto">
            войти
          </Link>
        )}
      </div>
    </div>
  );
}

export default Header;
