import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import usersService from "../usersService";

const initialState = {
  user: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// City get

export const userEdit = createAsyncThunk(
  "admin/users/edit",
  async (id, thunkAPI) => {
    try {
      return await usersService.userEdit(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const userEditSlice = createSlice({
  name: "userEdit",
  initialState,
  reducers: {
    clearUserEditState: (state) => {
      state.user = [];
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userEdit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userEdit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(userEdit.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      });
  },
});
export const { clearUserEditState } = userEditSlice.actions;

export default userEditSlice.reducer;
