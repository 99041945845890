import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import List from "../../../components/chat/chat-list/list";
import Header from "../../../components/header/header";
import SkeletonLoaderChatCreate from "../../../components/sceleton-loader/chats/sc-l-chat-create";
import Sidebar from "../../../components/sidebar/sidebar";
import { chatsListManegerSingle } from "../../../store/chat/chatListMenegerSingleSlice/chatListMenegerSingleSlice";
import { chatsList } from "../../../store/chat/chatListSlice/chatListSlice";
import { chatGet } from "../../../store/chat/сhatIndexSlice/chatIndexSlice";
import ChatCreate from "../chat-create/chat-create";

function ChatIndex() {
  const { list } = useSelector((state) => state.listChats);
  const [activePage, setActivePage] = useState(0);
  const { isLoading } = useSelector((state) => state.singleManagerChat);
  const { isLoadingClients } = useSelector((state) => state.chats);
  const [carId, setCarId] = useState();
  const [messages, setMessages] = useState([]);

  const { user } = useSelector((state) => state.profileGet);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const pageFromQuery = searchParams.get("page");
    const initialPage = pageFromQuery ? parseInt(pageFromQuery, 10) - 1 : 0;
    setActivePage(initialPage);
    dispatch(chatsList({ page: initialPage + 1 }));
  }, [searchParams]);

  const onChatSingle = (id) => {
    const fetchData = () => {
      if (["Admin", "Manager"].includes(user?.data?.role)) {
        dispatch(chatsListManegerSingle(id)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setMessages(res.payload.data.messages);
          }
        });
      } else {
        dispatch(chatGet(id)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setMessages(res.payload.data.messages);
          }
        });
      }
    };
    fetchData();
  };

  const handlePageChange = (selectedObject) => {
    const newPage = selectedObject.selected;
    setActivePage(newPage);

    // Обновляем query-параметры
    setSearchParams({ page: newPage + 1 });

    // Загружаем данные для новой страницы
    dispatch(
      chatsList({
        page: newPage + 1,
      })
    );

    window.scrollTo(0, 0);
  };

  return (
    <Sidebar>
      <div className="main__data">
        <Header
          title="Обсуждения"
          subtitle={`Здесь все чаты по автомобилям, по которым вы проявили активность`}
        />
        <div className="chat-collumn">
          {list && Object.keys(list).length !== 0 && (
            <List
              list={list?.data}
              onChatSingle={onChatSingle}
              setCarId={setCarId}
              car_id={carId}
            />
          )}

          {isLoading || isLoadingClients ? (
            <SkeletonLoaderChatCreate
              height="100%"
              width="62%"
              borderRadius="24px"
              margin="0 12px"
            />
          ) : (
            <>
              {carId && (
                <ChatCreate
                  car_id={carId}
                  messages={messages}
                  setMessages={setMessages}
                />
              )}
            </>
          )}
        </div>
        <div className="mt-3">
          <ReactPaginate
            pageCount={list?.data?.last_page}
            forcePage={activePage} // Здесь передаем текущее значение активной страницы
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            breakClassName={"pagination__item"}
            nextLinkClassName={"pagination__link"}
            nextLabel={`>`}
            previousLabel={`<`}
            pageClassName={"pagination__item"}
            disabledClassNae={"disabled"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </Sidebar>
  );
}

export default ChatIndex;
