import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import chatService from "../chatService";

const initialState = {
  manager_single_chat: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// chatsList

export const chatsListManegerSingle = createAsyncThunk(
  "chat/manager_single",
  async (id, thunkAPI) => {
    try {
      return await chatService.chatsListManegerSingle(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const chatsListManegerSingleSlice = createSlice({
  name: "chatsListManegerSingle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(chatsListManegerSingle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(chatsListManegerSingle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.manager_single_chat = action.payload;
      })
      .addCase(chatsListManegerSingle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.manager_single_chat = null;
      });
  },
});

export default chatsListManegerSingleSlice.reducer;
