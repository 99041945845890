import axios from "axios";

const API_URL = "/v1/telegram_bot";

const telegramBotSend = async (id, data) => {
  const response = await axios.get(API_URL + "/" + id, {
    params: data,
  });
  return { data: response.data, status: response.status };
};
//

const telegramService = {
  telegramBotSend,
};

export default telegramService;
