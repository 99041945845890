import React from "react";

function TextIcon() {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3332 1.00033C12.3332 0.779312 12.2454 0.56735 12.0891 0.41107C11.9328 0.25479 11.7209 0.166992 11.4998 0.166992H1.49984C1.27882 0.166992 1.06686 0.25479 0.910582 0.41107C0.754301 0.56735 0.666504 0.779312 0.666504 1.00033C0.666504 1.22134 0.754301 1.4333 0.910582 1.58958C1.06686 1.74586 1.27882 1.83366 1.49984 1.83366H5.6665V11.0003C5.6665 11.2213 5.7543 11.4333 5.91058 11.5896C6.06686 11.7459 6.27882 11.8337 6.49984 11.8337C6.72085 11.8337 6.93281 11.7459 7.08909 11.5896C7.24537 11.4333 7.33317 11.2213 7.33317 11.0003V1.83366H11.4998C11.7209 1.83366 11.9328 1.74586 12.0891 1.58958C12.2454 1.4333 12.3332 1.22134 12.3332 1.00033ZM16.4998 5.16699H11.4998C11.2788 5.16699 11.0669 5.25479 10.9106 5.41107C10.7543 5.56735 10.6665 5.77931 10.6665 6.00033C10.6665 6.22134 10.7543 6.4333 10.9106 6.58958C11.0669 6.74586 11.2788 6.83366 11.4998 6.83366H13.1665V11.0003C13.1665 11.2213 13.2543 11.4333 13.4106 11.5896C13.5669 11.7459 13.7788 11.8337 13.9998 11.8337C14.2209 11.8337 14.4328 11.7459 14.5891 11.5896C14.7454 11.4333 14.8332 11.2213 14.8332 11.0003V6.83366H16.4998C16.7208 6.83366 16.9328 6.74586 17.0891 6.58958C17.2454 6.4333 17.3332 6.22134 17.3332 6.00033C17.3332 5.77931 17.2454 5.56735 17.0891 5.41107C16.9328 5.25479 16.7208 5.16699 16.4998 5.16699Z"
        fill="#190D2E"
      />
    </svg>
  );
}

export default TextIcon;
