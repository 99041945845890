import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import AnimatedPage from "../../components/animated/animated";
import AuthForm from "../../components/auth-form/auth-form";
import ButtonWithBackground from "../../components/button/button-with-background";
import PhoneWithFlags from "../../components/phone-with-flags/phone-with-flags";
import { register } from "../../store/auth/register/registerSlice";
import { countries } from "../../utils/phone-countries-flags/phone-countries-flags";

function Register() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(countries[0]); // Начальная страна — Россия
  const [phoneMask, setPhoneMask] = useState(countries[0].mask); // Маска для номера телефона

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem("web_vinz");

  useEffect(() => {
    if (user !== null) {
      navigate("/setting");
    }
  }, [user, navigate]);

  const result = useSelector((state) => state.register);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    // Валидация на основе регулярного выражения для выбранной страны
    const phonePattern = selectedCountry.pattern; // Получаем паттерн из выбранной страны
    if (!phonePattern.test(value)) {
      setPhoneNumberError("Неправильный формат номера телефона");
    } else {
      setPhoneNumberError("");
    }
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e); // Обновляем выбранную страну
    setPhoneMask(e.mask); // Обновляем маску телефона для выбранной страны
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(register({ phone: phoneNumber })).then((res) => {
      if (res.payload.status === 200) {
        navigate("/verification");
      }
    });
  };

  return (
    <AnimatedPage>
      <AuthForm
        title="Добро пожаловать!"
        text="Уже есть аккаунт"
        link="/login"
        problem="Проблемы с регистрацией? "
        repeatSms={"register"}
      >
        <form onSubmit={handleSubmit}>
          <PhoneWithFlags
            onChange={handlePhoneNumberChange}
            phoneNumberError={phoneNumberError}
          />

          <ButtonWithBackground
            disabled={phoneNumberError == "" ? false : true}
          >
            {result && result?.isLoading ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Идет проверка...</span>
              </>
            ) : (
              "Получить код"
            )}
          </ButtonWithBackground>
        </form>
      </AuthForm>
    </AnimatedPage>
  );
}

export default Register;
