import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import Buttons from "../../../components/applicatons/applications-list/applications-button/applications-button";
import SinglePrice from "../../../components/applicatons/applications-list/applications-details-price/applications-details-price";
import ApplicationModalSelectAction from "../../../components/applicatons/applications-modal-select-action/applications-modal-select-action";
import ApplicationsRateModal from "../../../components/applicatons/applications-rate-modal/applications-rate-modal";
import Header from "../../../components/header/header";
import Pdf from "../../../components/pdf/pdf";
import Preloader from "../../../components/prelouder/prelouder";
import Sidebar from "../../../components/sidebar/sidebar";
import Single from "../../../components/single/single";
import { applicationsCheckInStock } from "../../../store/applications/applicationsCheckInStockSlice/applicationsCheckInStockSlice";
import {
  applicationsSingle,
  singleReset,
} from "../../../store/applications/applicationsSingleSlice/applicationsSingleSlice";

function ApplicationDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { single, isLoading } = useSelector(
    (state) => state.applicationsSingle
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [singleDetails, setSingleDetails] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(singleReset());
      await dispatch(applicationsSingle(id));
    };
    fetchData();
  }, [id]);

  const checkInStock = (id) => {
    swal({
      title: "Подтвержение действия",
      text: "Мы не гарантируем наличие данного ТС! Нажимая Уточнить в наличии , вы бронируете данное ТС за собой. Наши менеджеры уточнят статус ТС и свяжутся с вами в ближайшее время",
      icon: "warning",
      buttons: ["Отменить", "Да"],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(applicationsCheckInStock(id));
        swal("Авто успешно забронировано", {
          icon: "success",
        }).then(() => {
          navigate("/garage");
        });
      }
    });
  };

  const [showPhoneContacts, setShowPhoneContacts] = useState({});
  const openPhone = (itemId) => {
    setShowPhoneContacts((prevShowPhoneContacts) => ({
      ...prevShowPhoneContacts,
      [itemId]: true,
    }));
  };

  const [openModal, setOpenModal] = useState(false);
  const garage =
    single?.data?.status.code === "ready-to-buy" ||
    single?.data?.status.code === "check-availability" ||
    single?.data?.rate !== "";

  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <Sidebar>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          {/* Модальное окно для выбора действия */}
          {show && (
            <ApplicationModalSelectAction
              show={show}
              handleClose={handleClose}
              openPhone={openPhone}
              setOpenModal={setOpenModal}
              single={singleDetails}
            />
          )}
          {/*  */}
          {/* Модальное окно для ставки цены */}
          {openModal && (
            <ApplicationsRateModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              single={singleDetails}
            />
          )}

          {/*  */}
          <div className="main__data">
            <Header
              title={single?.data?.title}
              subtitle={single?.data?.auto_cat}
            >
              <div
                onClick={goBack}
                className="main__data-head-back cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.05006 9.40834L11.7667 4.70001C11.8442 4.6219 11.9364 4.5599 12.0379 4.5176C12.1395 4.47529 12.2484 4.45351 12.3584 4.45351C12.4684 4.45351 12.5773 4.47529 12.6789 4.5176C12.7804 4.5599 12.8726 4.6219 12.9501 4.70001C13.1053 4.85614 13.1924 5.06735 13.1924 5.2875C13.1924 5.50766 13.1053 5.71887 12.9501 5.87501L8.82506 10.0417L12.9501 14.1667C13.1053 14.3228 13.1924 14.534 13.1924 14.7542C13.1924 14.9743 13.1053 15.1855 12.9501 15.3417C12.8729 15.4204 12.7808 15.4831 12.6793 15.526C12.5777 15.5689 12.4686 15.5912 12.3584 15.5917C12.2481 15.5912 12.1391 15.5689 12.0375 15.526C11.9359 15.4831 11.8439 15.4204 11.7667 15.3417L7.05006 10.6333C6.96547 10.5553 6.89796 10.4606 6.85179 10.3552C6.80562 10.2498 6.78178 10.1359 6.78178 10.0208C6.78178 9.90575 6.80562 9.79192 6.85179 9.6865C6.89796 9.58108 6.96547 9.48637 7.05006 9.40834Z"
                    fill="#8D8796"
                  />
                </svg>
              </div>
            </Header>
            <Single single={single?.data}>
              <div className="detail-market">
                <SinglePrice item={single?.data} />
                <Pdf pdf={single?.data?.pdf}> Скачать PDF </Pdf>
                <Buttons
                  garage={garage}
                  item={single?.data}
                  checkInStock={checkInStock}
                  setShow={setShow}
                  setSingle={setSingleDetails}
                />
              </div>
            </Single>
          </div>
        </>
      )}
    </Sidebar>
  );
}

export default ApplicationDetail;
