import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import chatService from "../chatService";

const initialState = {
  list: {},
  isError: false,
  isSuccess: false,
  isLoadingList: false,
  message: "",
};

// chatsList

export const chatsList = createAsyncThunk(
  "chat/list",
  async (query, thunkAPI) => {
    try {
      return await chatService.chatsList(query);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const chatsListSlice = createSlice({
  name: "chatsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(chatsList.pending, (state) => {
        state.isLoadingList = true;
      })
      .addCase(chatsList.fulfilled, (state, action) => {
        state.isLoadingList = false;
        state.isSuccess = true;
        state.list = action.payload;
      })
      .addCase(chatsList.rejected, (state, action) => {
        state.isLoadingList = false;
        state.isError = true;
        state.message = action.payload;
        state.list = null;
      });
  },
});

export default chatsListSlice.reducer;
