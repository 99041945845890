import React from "react";
import { useSelector } from "react-redux";
import left_arrow from "../../../images/left-arrow.svg";
import noimage from "../../../images/no-image.png";
import { truncateString } from "../../../utils/text-slice";
import SkeletonLoaderChatCreate from "../../sceleton-loader/chats/sc-l-chat-create";

function List({ list, onChatSingle, setCarId, car_id }) {
  const { user } = useSelector((state) => state.profileGet);
  const { isLoadingList } = useSelector((state) => state.listChats);

  return (
    <div className="chat-collumn-one">
      {!isLoadingList
        ? list.data.map((item) => {
            return (
              <div
                onClick={() => {
                  onChatSingle(
                    ["Admin", "Manager"].includes(user?.data?.role)
                      ? item.chat_room_id
                      : item.model_id
                  );
                  setCarId(
                    ["Admin", "Manager"].includes(user?.data?.role)
                      ? item.chat_room_id
                      : item.model_id
                  );
                }}
                className={`chat-list-message d-flex align-items-center position-relative ${
                  car_id ==
                  (["Admin", "Manager"].includes(user?.data?.role)
                    ? item.chat_room_id
                    : item.model_id)
                    ? "active"
                    : ""
                }   ${item.status_id == 4 && "disabled"} `}
              >
                <div className="chat-list-content">
                  <div className="chat__header">
                    <div>
                      {item?.thumbnail ? (
                        <img src={item?.thumbnail} alt="" />
                      ) : (
                        <img src={noimage} className="row__img mt-0" />
                      )}
                    </div>
                    <div className="chat__header--title">
                      <div>{/* {user?.messages[0].} */}</div>
                      <span>{item?.title}</span>
                    </div>
                  </div>

                  {item.status_id == 4 ? (
                    <div className="chat__text">Авто снято с продажи</div>
                  ) : (
                    <div className="chat__text">
                      {item?.messages[0]?.role} :
                      {truncateString(item.messages[0].content, 125)}
                    </div>
                  )}

                  <span className="message-time">
                    {item.messages[0].created_at}
                  </span>
                </div>
                <div className="d-flex justify-content-center chat-list-arrow">
                  {item.unread_count !== 0 && (
                    <div className="chat-list-seen"></div>
                  )}
                  <img src={left_arrow} alt="" />
                </div>
              </div>
            );
          })
        : Array.from({ length: 15 }).map((_, index) => (
            <SkeletonLoaderChatCreate
              key={index}
              borderRadius="0 0 0 0"
              height="70px"
              width="100%"
              margin="2px 0"
            />
          ))}
    </div>
  );
}

export default List;
