import React, { useState } from "react";

function Attribute({ data, onSubmit, isLoading }) {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="settings__item w-100 mt-4 add-car-data__item">
      <form onSubmit={(e) => onSubmit(e, formData)}>
        <div className=" d-flex align-items-end flex-wrap  add-car-data__item">
          {/* Диагностика Винз */}
          <div className="row__col-4 mt-3">
            <h4>Диагностика Винз</h4>
            <textarea
              rows="3"
              name="vinz"
              placeholder="Не указано"
              defaultValue={data?.data?.settings?.vinz}
              onChange={handleChange}
            ></textarea>
          </div>
          {/*  */}

          {/* От частных экспертов */}
          <div className="row__col-4 mt-3">
            <h4>От частных экспертов</h4>
            <textarea
              rows="3"
              name="private"
              placeholder="Не указано"
              defaultValue={data?.data?.settings?.private}
              onChange={handleChange}
            ></textarea>
          </div>
          {/*  */}

          {/* От владельца */}
          <div className=" row__col-4 mt-3">
            <h4>От владельца</h4>
            <textarea
              rows="3"
              name="owner"
              placeholder="Не указано"
              defaultValue={data?.data?.settings?.owner}
              onChange={handleChange}
            ></textarea>
          </div>
          {/*  */}

          {/* На площадке */}
          <div className="row__col-4 mt-3">
            <h4>На площадке</h4>
            <textarea
              rows="3"
              name="area"
              placeholder="Не указано"
              defaultValue={data?.data?.settings?.area}
              onChange={handleChange}
            ></textarea>
          </div>
          {/*  */}

          {/* В пути */}
          <div className="row__col-4 mt-3">
            <h4>В пути</h4>
            <textarea
              rows="3"
              name="way"
              placeholder="Не указано"
              defaultValue={data?.data?.settings?.way}
              onChange={handleChange}
            ></textarea>
          </div>
          {/*  */}

          {/* У продавца */}
          <div className="row__col-4 mt-3">
            <h4>У продавца</h4>
            <textarea
              rows="3"
              name="salesman"
              placeholder="Не указано"
              defaultValue={data?.data?.settings?.salesman}
              onChange={handleChange}
            ></textarea>
          </div>
          {/*  */}
          {/* Под заказ */}
          <div className="row__col-4 mt-3">
            <h4>Под заказ</h4>
            <textarea
              rows="3"
              name="on_order"
              placeholder="Не указано"
              defaultValue={data?.data?.settings?.on_order}
              onChange={handleChange}
            ></textarea>
          </div>
          {/*  */}
        </div>
        <button
          type="submit"
          name="save"
          className={`gradient-btn-blue admin-btn-padding xp-3`}
        >
          {isLoading ? (
            <>
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Сохранение...</span>
            </>
          ) : (
            "Сохранить"
          )}
        </button>
      </form>
    </div>
  );
}

export default Attribute;
