import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/sidebar/sidebar";
import { applicationsGet } from "../../store/applications/applicationsGetSlice/applicationsGetSlice";
import { profile } from "../../store/profile/profileGetSlice/profileGetSlice";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ListOfCars from "../../components/applicatons/applications-list/applications-list";
import Filter from "../../components/filter/filter";
import { applicationsCheckInStock } from "../../store/applications/applicationsCheckInStockSlice/applicationsCheckInStockSlice";

function Applications() {
  const { applications, isLoading, count } = useSelector(
    (state) => state.applicationsGet
  );
  const queryFilter = useSelector((state) => state.filterQuery);
  const [show, setShow] = useState(false);
  const [single, setSingle] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState({ page: 1 });

  useEffect(() => {
    const fetchData = async () => {
      if (!queryFilter) {
        // await dispatch(applicationReset());
      }

      await dispatch(applicationsGet({ ...queryFilter, ...query }));
      await dispatch(profile());
    };
    fetchData();
  }, [query]);

  const handlePageChange = (selectedObject) => {
    setQuery({
      ...query,
      onlyCount: false,
      page: selectedObject.selected + 1,
    });

    window.scrollTo(0, 0);
  };

  const checkInStock = (id) => {
    swal({
      title: "Подтвержение действия",
      text: "Мы не гарантируем наличие данного ТС! Нажимая Уточнить в наличии , вы бронируете данное ТС за собой. Наши менеджеры уточнят статус ТС и свяжутся с вами в ближайшее время",
      icon: "warning",
      buttons: ["Отменить", "Да"],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(applicationsCheckInStock(id));
        swal("Авто успешно забронировано", {
          icon: "success",
        }).then(() => {
          navigate("/garage");
        });
      }
    });
  };

  return (
    <Sidebar>
      {applications && Object.keys(applications).length !== 0 && (
        <>
          <ListOfCars
            title={"Маркет"}
            applications={applications}
            show={show}
            handleClose={handleClose}
            single={single}
            setSingle={setSingle}
            setOpenFilter={setOpenFilter}
            openFilter={openFilter}
            setShow={setShow}
            checkInStock={checkInStock}
            handlePageChange={handlePageChange}
            setSearch={setQuery}
            isLoading={isLoading}
          />

          <Filter
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            isLoading={isLoading}
            count={count}
            query={queryFilter}
            getData={applicationsGet}
            type={{ type: "car" }}
          />
        </>
      )}
    </Sidebar>
  );
}

export default Applications;
