import moment from "moment";
import React from "react";

function AdminApplicatoinListStatus({ item }) {
  const renderStatus = () => {
    switch (item?.status?.code) {
      case "issued": {
        return (
          <>
            <div className="status__application-admin-issued">Актуально до</div>
            <div className="status__application-admin-time">
              {item.indefinitely
                ? "Бесрочно"
                : moment(item?.show_hide_btn.date).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
            </div>
          </>
        );
      }
      case "storage": {
        return (
          <>
            <div className="status__application-admin-storage">
              Спец. размещение до
            </div>
            <div className="status__application-admin-time">
              {moment(item?.special_offer_date).format("YYYY-MM-DD HH:mm:ss")}
            </div>
          </>
        );
      }
      case "ready-to-buy": {
        return (
          <>
            <div className="status__application-admin-ready-to-buy">
              Согласование сделки
            </div>

            <div className="status__application-admin-time">
              {moment(item?.ready_to_buy_date.date).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            </div>
          </>
        );
      }
      case "deal-fell-through": {
        return (
          <>
            <div className="status__application-admin-storage">
              Сделка соравалась
            </div>
            <div className="status__application-admin-time">
              {moment(item?.ready_to_buy_date.date).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            </div>
          </>
        );
      }
      case "deal-complated": {
        return (
          <>
            <div className="status__application-admin-issued">
              Сделка завершена
            </div>
            <div className="status__application-admin-time">
              {moment(item?.ready_to_buy_date.date).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            </div>
          </>
        );
      }
    }
  };

  return <div className="status__application-admin">{renderStatus()}</div>;
}

export default AdminApplicatoinListStatus;
