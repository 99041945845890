// PhoneInput.js
import React, { useState } from "react";
import ReactInputMask from "react-input-mask";
import Select from "react-select";
import { countries } from "../../utils/phone-countries-flags/phone-countries-flags";

function PhoneWithFlags({ onChange, phoneNumberError, defaultValue }) {
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [phoneMask, setPhoneMask] = useState(countries[0].mask);
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);

    setPhoneMask(selectedOption.mask);
  };

  const handlePhoneChange = (e) => {
    onChange(e);
  };

  return (
    <>
      <div className="mb-3 login__select--flags">
        <Select
          value={selectedCountry}
          onChange={handleCountryChange}
          options={countries}
          styles={{
            menu: (base) => ({
              ...base,
              width: "150px",
              textAlign: "left",
            }),
            control: (base) => ({
              ...base,
              width: 80,
              marginRight: "10px",
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
            }),
            option: (base, state) => ({
              ...base,
              fontSize: "11px",
              fontWeight: "bold",
              textAlign: "left",
              color: state.isSelected ? "#fff" : "#333",
              backgroundColor: state.isSelected ? "#333" : "#fff",
              "&:hover": {
                backgroundColor: "#f0f0f0",
                color: "black",
                cursor: "pointer",
              },
            }),
          }}
        />
      </div>
      <div className="position-relative">
        <ReactInputMask
          mask={phoneMask}
          maskChar="_"
          name="phone"
          onChange={handlePhoneChange}
          className="mb-3 mt-4 padding-phone-custom"
          placeholder={phoneMask.replace(/\\+/g, "")} // Убирает обратные слэши и заменяет цифры на "_"
          defaultValue={defaultValue}
          required
        />
        {phoneNumberError && (
          <p className="error-message">{phoneNumberError}</p>
        )}
      </div>
    </>
  );
}

export default PhoneWithFlags;
