import React, { useState } from "react";
import Sidebar from "../../../components/sidebar/sidebar";

import moment from "moment";
import { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AutoInfo from "../../../components/create-edit-application/auto-info/auto_info";
import AutoParameters from "../../../components/create-edit-application/auto-parameters/auto-parameters";
import { orderImageEditSlice } from "../../../store/image/image-order-edit/uploadImageEdit";
import { lotsEdit } from "../../../store/seller/lotsEditSlice/lotsEditSlice";
import { lotsUpdate } from "../../../store/seller/lotsUpdateSlice/lotsUpdateSlice";

function SellerAutoEdit() {
  const [key, setKey] = useState("seller-tab-1");
  const { id } = useParams();
  const handleSelect = (k) => {
    setKey(k);
  };
  const [isTab1Disabled, setIsTab1Disabled] = useState(false);
  const [isTab2Disabled, setIsTab2Disabled] = useState(false);
  const [attachmentOrder, setAttachmentOrder] = useState({});
  const { editInfo } = useSelector((state) => state.lotsEdit);
  const userLocal = JSON.parse(localStorage.getItem("web_vinz"));

  const { token } = useSelector((state) => state.imageToken);

  if (
    localStorage.getItem("attachment_token") === null ||
    localStorage.getItem("attachment_token") === "undefined"
  ) {
    localStorage.setItem("attachment_token", token?.data);
  }

  const [isOtherButtonDisabled, setIsOtherButtonDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [createData, setCreateData] = useState({});
  const result = useSelector((state) => state.lotsUpdate);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lotsEdit(id)).then((res) => {
      if (res.payload.status === 200) {
        const data = { ...res.payload.data.greade }; // Создаем копию объекта
        console.log(res);

        const currentDate = moment(); // Текущая дата и время как объект moment
        const auctionEndDate = moment(data.end_auction, "MM.DD.YYYY HH:mm:ss"); // Преобразуем строку в объект moment

        if (auctionEndDate.isBefore(currentDate)) {
          // Если дата окончания аукциона в прошлом, сбрасываем поля
          data.end_auction = "";
          data.binding_offer = "";
        }

        setCreateData(data);
      }
    });
  }, [id]);

  // Валидация для кнопки перехода на второй таб
  useEffect(() => {
    // Валидация первого таба
    const tab1Inputs =
      createData.vin &&
      createData.cat_auto &&
      createData.end_auction &&
      createData.binding_offer &&
      createData.region_id;
    setIsOtherButtonDisabled(!tab1Inputs);
    //

    // Валидация второго таба
    const tab2Inputs =
      createData.car_type_id === 27 ||
      (createData.car_type_id && createData.car_mark_id);
    setIsTab2Disabled(tab2Inputs);
    //
    // Валидация кнопок для создание лота
    const bothTabsInputs = tab1Inputs && tab2Inputs;
    setIsSubmitDisabled(bothTabsInputs);
    //
  }, [createData]);
  //
  const renderContent = () => {
    switch (key) {
      case "seller-tab-1":
        return (
          <AutoParameters
            setKey={setKey}
            setCreateData={setCreateData}
            createData={createData}
            isTab1Disabled={isTab1Disabled}
            setIsTab1Disabled={setIsTab1Disabled}
            setIsOtherButtonDisabled={setIsOtherButtonDisabled}
            keyTab={key}
            editId={id}
          />
        );
      case "seller-tab-2":
        return (
          <AutoInfo
            setCreateData={setCreateData}
            createData={createData}
            keyTab={key}
            isTab2Disabled={isTab2Disabled}
            editId={id}
            setAttachmentOrder={setAttachmentOrder}
            image={editInfo.data.greade.images}
          />
        );
      default:
        return null;
    }
  };

  const onSubmitUpdate = (e) => {
    e.preventDefault();
    if (Array.isArray(createData?.docs) && createData.docs.length > 0) {
      if (!(createData.docs[0] instanceof File)) {
        delete createData.docs;
      }
    } else {
    }

    Object.keys(attachmentOrder).length !== 0
      ? dispatch(orderImageEditSlice(attachmentOrder)).then((res) => {
          if (res.payload.status === 200) {
            dispatch(lotsUpdate({ data: createData, id: id })).then((res) => {
              if (res.payload.status === 200) {
                toast.success("Успешно обновлен");
                localStorage.removeItem("attachment_token");
                if (userLocal.role === "Seller") {
                  window.location.replace("/seller/1");
                } else {
                  window.location.replace("/admin/lots/1");
                }
              } else {
                toast.error("Произошла ошибка");
              }
            });
          }
        })
      : dispatch(lotsUpdate({ data: createData, id: id })).then((res) => {
          if (res.payload.status === 200) {
            toast.success("Успешно обновлен");
            localStorage.removeItem("attachment_token");
            if (userLocal.role === "Seller") {
              window.location.replace("/seller/1");
            } else {
              window.location.replace("/admin/lots/1");
            }
          } else {
            toast.error("Произошла ошибка");
          }
        });
  };

  return (
    <Sidebar>
      <form onSubmit={onSubmitUpdate} className="main__data">
        <div className="main__data-head">
          <div className="main__data-info">
            <div className="main__data-heder-title">
              Редактирование автомобиля
            </div>
            <div className="main__data-heder-subtitle">
              Укажите информацию об автомобиле и параметры лота, чтобы запустить
              аукцион
            </div>
          </div>
          {result && result?.isLoading ? (
            <div className="ml-auto blue-outline-btn w-25">
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Идет Процесс...</span>
            </div>
          ) : (
            <>
              <button
                type="submit"
                className={`blue-outline-btn ml-auto ${
                  isSubmitDisabled ? "" : "disablet"
                } ${result?.isLoading && "disablet"}`}
                onClick={(e) => setCreateData({ ...createData, status_id: 4 })}
              >
                {result && result?.isLoading ? (
                  <>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Сохраняем в черновик...</span>
                  </>
                ) : (
                  "Сохранить в черновик"
                )}
              </button>
              <button
                type="submit"
                className={`gradient-btn-blue ${
                  isSubmitDisabled ? "" : "disablet-gradinet-btn"
                }`}
                onClick={(e) => setCreateData({ ...createData, status_id: 1 })}
              >
                {result && result?.isLoading ? (
                  <>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Создается лот...</span>
                  </>
                ) : (
                  " Разместить лот"
                )}
              </button>
            </>
          )}
        </div>
        <div className="add-car">
          <Tabs activeKey={key} onSelect={handleSelect}>
            <Tab eventKey="seller-tab-1" title="Параметры лота" />
            <Tab
              eventKey="seller-tab-2"
              title="Информация об автомобиле"
              disabled={isOtherButtonDisabled}
            />
          </Tabs>
          <div className="tab-content">{renderContent()}</div>
        </div>
      </form>
    </Sidebar>
  );
}

export default SellerAutoEdit;
