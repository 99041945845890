import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";

function AuthForm(props) {
  return (
    <div className="container text-center justify-content-center d-flex">
      <div className="col-lg-4">
        <div className="auth-form">
          <img src={logo} alt="Логотип" />
          <h1>{props.title}</h1>
          <p>{props.description}</p>
          {props.children}
          <div className="d-flex justify-content-around mt-2">
            {props.text && (
              <Link to={props.link} className="auth__text">
                {props.text}
              </Link>
            )}
            {props.resetPassword && (
              <Link to="/reset-password-verify" className="auth__text">
                Забыли пароль ?
              </Link>
            )}
          </div>

          <div className="text-center problem-with-auth mt-3">
            {props.problem}
            <Link
              to="https://t.me/vinz_support_bot"
              className="auth__text"
              target="_blank"
            >
              Написать в телеграмм
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthForm;
