import ar from "../../images/flags/ar.png";
import bl from "../../images/flags/bl.png";
import kg from "../../images/flags/kg.png";
import kz from "../../images/flags/kz.png";
import ru from "../../images/flags/ru.png";
import uz from "../../images/flags/uz.png";

export const countries = [
  {
    value: "ru",
    label: (
      <span>
        <img src={ru} alt="Russia" /> Россия
      </span>
    ),
    mask: "+7 (999) 999-99-99",
    pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, // Регулярное выражение для России
  },
  {
    value: "kz",
    label: (
      <span>
        <img src={kz} alt="Казахстан" /> Казахстан
      </span>
    ),
    mask: "+7 999 999 99 99",
    pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, // Регулярное выражение для Казахстана
  },
  {
    value: "kg",
    label: (
      <span>
        <img src={kg} alt="Кыргызстан" /> Кыргызстан
      </span>
    ),
    mask: "+\\9\\96 999 999 999",
    pattern: /^\+996 \(\d{3}\) \d{3}-\d{3}$/, // Регулярное выражение для Кыргызстана
  },
  {
    value: "bl",
    label: (
      <span>
        <img src={bl} alt="Беларусь" /> Беларусь
      </span>
    ),
    mask: "+375 99 999 99 99",
    pattern: /^\+375 \(\d{2}\) \d{3}-\d{2}-\d{2}$/, // Регулярное выражение для Беларуси
  },
  {
    value: "uz",
    label: (
      <span>
        <img src={uz} alt="Узбекистан" /> Узбекистан
      </span>
    ),
    mask: "+\\9\\98 99 999 99 99",
    pattern: /^\+998 \(\d{2}\) \d{3}-\d{2}-\d{2}$/, // Регулярное выражение для Узбекистана
  },
  {
    value: "am",
    label: (
      <span>
        <img src={ar} alt="Армения" /> Армения
      </span>
    ),
    mask: "+374 99 999 999",
    pattern: /^\+374 \(\d{2}\) \d{3}-\d{3}$/, // Регулярное выражение для Армении
  },
];
