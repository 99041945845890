import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import noimage from "../../../images/no-image.png";

import { getDatabase, off, onValue, ref } from "firebase/database";
import { сhatManagerSend } from "../../../store/chat/сhatManagerSendSlice/сhatManagerSendSlice";
import { сhatSend } from "../../../store/chat/сhatSendSlice/сhatSendSlice";
import MessageList from "./../../../components/chat/chat-create-component/message-list";

function ChatCreate({ car_id, messages, setMessages }) {
  const { user } = useSelector((state) => state.profileGet);
  const { chats } = useSelector((state) => state.chats);
  const { manager_single_chat } = useSelector(
    (state) => state.singleManagerChat
  );
  const chat_single = ["Admin", "Manager"].includes(user?.data?.role)
    ? manager_single_chat
    : chats;

  const { isLoading } = useSelector((state) => state.chatsCreate);
  const dispatch = useDispatch();

  useEffect(() => {
    const database = getDatabase();
    const knot = `chat_room/${user?.data.user[0].id}/${chat_single.data.chat_room_id}`;
    const messagesRef = ref(database, knot);

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      if (snapshot.exists()) {
        const newMessage = snapshot.val();
        // Проверяем, нет ли уже этого сообщения
        setMessages((prevMessages) => {
          const isDuplicate = prevMessages.some(
            (message) => message.id === newMessage.id // Предполагаем, что у сообщений есть уникальное поле `id`
          );
          if (isDuplicate) {
            console.log("Duplicate message detected:", newMessage);
            return prevMessages; // Если дубликат, возвращаем текущий список
          }
          return [newMessage, ...prevMessages]; // Добавляем только уникальное сообщение
        });
      } else {
        console.log("Snapshot does not exist");
      }
    });

    return () => {
      off(messagesRef); // Отключаем слушатель при размонтировании
    };
  }, [car_id]);

  const sendApi = (message) => {
    ["Admin", "Manager"].includes(user?.data?.role)
      ? dispatch(
          сhatManagerSend({ id: car_id, data: { content: message } })
        ).then((res) => {
          if (res.payload.status === 201) {
            setMessages((prevMessages) => [res.payload.data, ...prevMessages]);
          }
        })
      : dispatch(сhatSend({ id: car_id, data: { content: message } })).then(
          (res) => {
            if (res.payload.status === 201) {
              setMessages((prevMessages) => [
                res.payload.data.data,
                ...prevMessages,
              ]);
            }
          }
        );
  };
  console.log(chat_single?.data);

  return (
    <div className="chat-collumn-two">
      <div className="chat-list-message chat-window">
        <div className="chat__header">
          <div>
            {chat_single?.data?.thumbnail ? (
              <img src={chat_single?.data?.thumbnail} alt="" />
            ) : (
              <img src={noimage} className="row__img mt-0" />
            )}
          </div>
          <div className="chat__header--title">
            <div className="d-flex ">
              {user?.data?.user[0]?.name !== ""
                ? user?.data?.user[0]?.name
                : user?.data?.user[0]?.phone}
            </div>
            {![4, 5, 6, 7, 8, 9].includes(chat_single?.data?.status_id) && (
              <Link
                to={`/applications/${chat_single?.data?.status_id}/${chat_single?.data?.model_id}`}
              >
                {chat_single?.data?.title}
              </Link>
            )}
          </div>
        </div>
        <MessageList
          chats={messages}
          postChatMessage={sendApi}
          isLoading={isLoading}
          status_id={chat_single?.data?.status_id}
        />
      </div>
    </div>
  );
}

export default ChatCreate;
