import React from "react";

function CircleIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99984 0.666992C4.4165 0.666992 0.666504 4.41699 0.666504 9.00033C0.666504 13.5837 4.4165 17.3337 8.99984 17.3337C13.5832 17.3337 17.3332 13.5837 17.3332 9.00033C17.3332 4.41699 13.5832 0.666992 8.99984 0.666992Z"
        fill="#190D2E"
      />
    </svg>
  );
}

export default CircleIcon;
