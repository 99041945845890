import moment from "moment";
import "moment/locale/ru";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header/header";
import RatesLotsComponets from "../../components/rates-lots-component/rates-lots-component";
import RatesChangePrice from "../../components/rates/rates-change-price";
import RatesStatus from "../../components/rates/rates-status/rates-status";
import SkeletonLoaderChatCreate from "../../components/sceleton-loader/chats/sc-l-chat-create";
import Sidebar from "../../components/sidebar/sidebar";
import op from "../../images/op.svg";
import { greade } from "../../store/auction/greadeSlice/greadeSlice";
import { formatNumber } from "../../utils/format-price";

function Rates() {
  const { greadeData, count, isLoading } = useSelector(
    (state) => state.greadeGet
  );
  const [data, setData] = useState({});
  const [errorPrice, setErrorPrice] = useState("");
  const [title, setTitle] = useState("");
  const { id } = useParams();
  const [openFilter, setOpenFilter] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState({ page: 1 });

  useEffect(() => {
    dispatch(greade({ ...query, filter: parseInt(id) })); // Отправка запроса с текущими параметрами
  }, [query, navigate]);

  useEffect(() => {
    switch (id) {
      case "3": {
        setTitle("Завершенные");
        break;
      }
      case "4": {
        setTitle("Выигранные");
        break;
      }
      case "5": {
        setTitle("Сделки");
        break;
      }
    }
  }, [id]);

  const handlePageChange = (selectedObject) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: selectedObject.selected + 1, // Обновление страницы
    }));
    window.scrollTo(0, 0);
  };
  moment.locale("ru");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <Sidebar>
      <div className={`main__data ${openFilter && "filter__active"}`}>
        <Header
          title={title}
          subtitle={`Открыто ${greadeData?.data?.count} лотов`}
          search={true}
          setSearch={setQuery}
        />

        <div className="ml-auto mt-3">
          <button
            type="button"
            className={`btn-filter ${openFilter && "active"}`}
            onClick={() => setOpenFilter(!openFilter)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15.8334 1.66667H4.16675C3.50371 1.66667 2.86782 1.93006 2.39898 2.3989C1.93014 2.86775 1.66675 3.50363 1.66675 4.16667V5.14167C1.66663 5.48579 1.73755 5.82623 1.87508 6.14167V6.19167C1.99281 6.45915 2.15957 6.70222 2.36675 6.90834L7.50008 12.0083V17.5C7.4998 17.6416 7.53561 17.781 7.60414 17.9049C7.67267 18.0289 7.77165 18.1333 7.89175 18.2083C8.02437 18.2905 8.17739 18.3338 8.33341 18.3333C8.46387 18.3326 8.59231 18.3012 8.70841 18.2417L12.0417 16.575C12.1792 16.5058 12.2947 16.3998 12.3756 16.2689C12.4565 16.138 12.4996 15.9872 12.5001 15.8333V12.0083L17.6001 6.90834C17.8073 6.70222 17.974 6.45915 18.0917 6.19167V6.14167C18.2408 5.8287 18.3231 5.48815 18.3334 5.14167V4.16667C18.3334 3.50363 18.07 2.86775 17.6012 2.3989C17.1323 1.93006 16.4965 1.66667 15.8334 1.66667ZM11.0751 11.075C10.9978 11.1529 10.9367 11.2452 10.8953 11.3468C10.8538 11.4483 10.8328 11.557 10.8334 11.6667V15.3167L9.16675 16.15V11.6667C9.16738 11.557 9.14636 11.4483 9.10489 11.3468C9.06342 11.2452 9.00232 11.1529 8.92508 11.075L4.50841 6.66667H15.4917L11.0751 11.075ZM16.6667 5.00001H3.33341V4.16667C3.33341 3.94566 3.42121 3.7337 3.57749 3.57742C3.73377 3.42114 3.94573 3.33334 4.16675 3.33334H15.8334C16.0544 3.33334 16.2664 3.42114 16.4227 3.57742C16.579 3.7337 16.6667 3.94566 16.6667 4.16667V5.00001Z"
                fill="url(#paint0_linear_1709_2044)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1709_2044"
                  x1="4.64453"
                  y1="1.66667"
                  x2="16.826"
                  y2="2.14219"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00C696" />
                  <stop offset="1" stopColor="#00A57D" />
                </linearGradient>
              </defs>
            </svg>
          </button>
        </div>

        <div className="card">
          <div className="card__list">
            {!isLoading ? (
              greadeData?.data?.greade.length !== 0 ? (
                greadeData?.data?.greade.map((item) => {
                  const formattedDate = moment(item.end_auction).format(
                    "D MMMM YYYY [в] HH:mm"
                  );

                  return (
                    <RatesLotsComponets
                      formattedDate={formattedDate}
                      item={item}
                    >
                      <div className="card__item-right-bar">
                        <div className="card__item-price-title">
                          Вы сделали ставку:
                          {(item?.status_id == 3 || item?.status_id == 6) &&
                            item.op_link && (
                              <Link
                                to={item.op_link}
                                target="_blank"
                                className="d-flex align-items-center card__item-price-op"
                              >
                                <img src={op} alt="" />
                                ОП
                              </Link>
                            )}
                        </div>
                        <div className="card__item-price">
                          {formatNumber(item?.greade_price?.price)} ₽
                        </div>
                        {item?.status_id === 1 && (
                          <div
                            className="gray-text-link mt-auto cursor-pointer"
                            onClick={() => {
                              setShow(true);
                              setData({
                                oldprice: item?.greade_price?.price,
                                greadeId: item.id,
                              });
                              setErrorPrice("");
                            }}
                          >
                            Изменить ставку
                          </div>
                        )}

                        <RatesStatus item={item} />
                      </div>
                    </RatesLotsComponets>
                  );
                })
              ) : (
                <div className="mb-4">Ничего не найдено!</div>
              )
            ) : (
              Array.from({ length: 20 }).map((_, index) => (
                <SkeletonLoaderChatCreate
                  key={index}
                  borderRadius="12px"
                  height="170px"
                  width="100%"
                  margin="8px 0 0 0"
                />
              ))
            )}
          </div>
          {greadeData?.data?.greade.length !== 0 && (
            <ReactPaginate
              pageCount={greadeData?.data?.paginate}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              breakClassName={"pagination__item"}
              nextLinkClassName={"pagination__link"}
              nextLabel={`>`}
              previousLabel={`<`}
              pageClassName={"pagination__item"}
              disabledClassNae={"disabled"}
              activeClassName={"active"}
            />
          )}
        </div>

        {show && (
          <RatesChangePrice
            show={show}
            handleClose={handleClose}
            setShow={setShow}
            data={data}
            setData={setData}
            errorPrice={errorPrice}
            setErrorPrice={setErrorPrice}
          />
        )}
      </div>
    </Sidebar>
  );
}

export default Rates;
