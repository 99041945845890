import {
  Canvas,
  Circle,
  Image as FabricImage,
  Line,
  PencilBrush,
  Polygon,
  Rect,
  Textbox,
} from "fabric";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { applicationsAdminEdit } from "../../store/admin/application/applicationAdminEditSlice/applicationAdminEditSlice";
import { updateImageSlice } from "../../store/image/image-update/imageUpdate";
import { lotsEdit } from "../../store/seller/lotsEditSlice/lotsEditSlice";
import BrushIcon from "../icons/brush";
import CircleIcon from "../icons/circle";
import CropIcon from "../icons/crop";
import RotateLeftIcon from "../icons/rotate-left";
import RotateRightIcon from "../icons/rotate-right";
import SquareIcon from "../icons/square";
import TransformIcon from "../icons/transform";
import TextIcon from "./../icons/text";
import Preloader from "./../prelouder/prelouder";

function ImageEditorModal({
  image,
  onClose,
  show,
  setSortedList,
  add,
  market,
  setIsDraggingDisabled,
}) {
  const [drawingColor, setDrawingColor] = useState("#E4E4E4");
  const [canvas, setCanvas] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [points, setPoints] = useState([]);
  const [polygon, setPolygon] = useState(null);
  const [line, setLine] = useState(null);
  const [startCircle, setStartCircle] = useState(null);
  const [cropRect, setCropRect] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [prelouder, setPrelouder] = useState(false);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [drawingTool, setDrawingTool] = useState("brush");
  const [isTextMode, setIsTextMode] = useState(false); // Режим ввода текста
  const [text, setText] = useState(""); // Текст для добавления
  const [isDrawingShape, setIsDrawingShape] = useState(false);

  const [isDrawingSquare, setIsDrawingSquare] = useState(false);
  const [square, setSquare] = useState(null);

  const [circle, setCircle] = useState(null);
  const [startPoint, setStartPoint] = useState(null);

  const { id } = useParams();
  const tokenAttachment = localStorage.getItem("attachment_token");
  const { isLoading } = useSelector((state) => state.imageEditUpload);
  const dispatch = useDispatch();

  const initCanvas = useCallback((node) => {
    if (node !== null) {
      const canvasInstance = new Canvas(node, {
        selection: false,
        width: 800,
        height: 600,
      });
      setCanvas(canvasInstance);
      console.log("Canvas initialized", canvasInstance);
    }
  }, []);

  useEffect(() => {
    if (canvas) {
      const rect = canvas.getElement().getBoundingClientRect();
      console.log("Canvas bounding rect:", rect);
      console.log("Canvas size:", canvas.width, canvas.height);
    }
  }, [canvas]);

  const resetStates = () => {
    setPoints([]);
    setPolygon(null);
    setLine(null);
    setSquare(null);
    setCircle(null);
    setOriginalImage(null);
  };

  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "auto";
    return () => {
      resetStates(); // Сбрасываем состояния при закрытии модального окна
      document.body.style.overflow = "auto";
    };
  }, [show]);

  const loadImage = (url) => {
    let proxyUrl = ``;
    if (process.env.NODE_ENV === "production") {
      proxyUrl = url;
    } else {
      proxyUrl = `http://vinz.loc/proxy?url=${encodeURIComponent(url)}`;
    }

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = proxyUrl;

    img.onload = () => {
      const fabricImg = new FabricImage(img, {
        selectable: false,
        evented: false,
      });

      const scale = Math.min(800 / img.width, 600 / img.height);
      fabricImg.scaleToWidth(img.width * scale);
      fabricImg.scaleToHeight(img.height * scale);

      canvas.clear();
      canvas.add(fabricImg);
      canvas.setDimensions({
        width: img.width * scale,
        height: img.height * scale,
      });
      canvas.renderAll();

      setOriginalImage(fabricImg);
    };

    img.onerror = () => {
      console.error("Failed to load image");
    };
  };

  useEffect(() => {
    if (canvas && image?.url) {
      loadImage(image.url);
    }

    return () => {
      if (canvas) {
        console.log("Disposing of canvas");
        canvas.clear(); // Очищаем холст от всех объектов
        canvas.dispose();
      }
    };
  }, [canvas, image, add]);

  // Логика рисования многоугольника
  useEffect(() => {
    if (!canvas) return;

    const handleMouseDown = (opt) => {
      if (!isDrawing) return;

      const pointer = canvas.getPointer(opt.e);
      const currentPoints = [...points, { x: pointer.x, y: pointer.y }];

      if (points.length > 1 && isCloseToFirstPoint(pointer, points[0])) {
        const newPolygon = new Polygon(points, {
          fill: drawingColor,
          selectable: false,
          evented: false,
        });
        canvas.add(newPolygon);
        resetDrawing();
      } else {
        setPoints(currentPoints);

        if (polygon) canvas.remove(polygon);
        const newPolygon = new Polygon(currentPoints, {
          stroke: drawingColor,
          strokeWidth: 2,
          fill: "rgba(0,0,0,0)",
          selectable: false,
          evented: false,
        });
        canvas.add(newPolygon);
        setPolygon(newPolygon);

        if (line) canvas.remove(line);
        const newLine = new Line([pointer.x, pointer.y, pointer.x, pointer.y], {
          stroke: drawingColor,
          strokeWidth: 2,
          selectable: false,
          evented: false,
        });
        canvas.add(newLine);
        setLine(newLine);
        console.log(newLine);

        if (!startCircle) {
          const circle = new Circle({
            left: pointer.x,
            top: pointer.y,
            radius: 5,
            fill: "red",
            selectable: false,
            evented: false,
          });
          canvas.add(circle);
          setStartCircle(circle);
        }
      }
    };

    const handleMouseMove = (opt) => {
      if (!isDrawing || !line) return;
      const pointer = canvas.getPointer(opt.e);
      line.set({ x2: pointer.x, y2: pointer.y });
      canvas.renderAll();
    };

    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:move", handleMouseMove);

    return () => {
      canvas.off("mouse:down", handleMouseDown);
      canvas.off("mouse:move", handleMouseMove);
    };
  }, [canvas, points, drawingColor, line, polygon, startCircle, isDrawing]);

  const isCloseToFirstPoint = (point, firstPoint) => {
    const distance = Math.sqrt(
      Math.pow(point.x - firstPoint.x, 2) + Math.pow(point.y - firstPoint.y, 2)
    );
    return distance < 10;
  };

  const resetDrawing = () => {
    setPoints([]);
    setPolygon(null);
    setLine(null);
    if (startCircle) {
      canvas.remove(startCircle);
      setStartCircle(null);
    }
  };

  const handleSave = async () => {
    setPrelouder(true);
    try {
      const dataURL = canvas.toDataURL({
        format: "png",
        quality: 1,
      });
      const imageData = {
        id: image.id,
        data: { file: dataURL, name: image?.name },
      };

      if (add) {
        const res = await dispatch(updateImageSlice(imageData));
        console.log(res);

        if (res.payload.status === 200) {
          const newImage = {
            id: res.payload.data.attachments,
            url: res.payload.data.url,
          };

          setSortedList((prevImages) => {
            // Фильтруем старое изображение, если оно существует
            const filteredImages = prevImages.filter(
              (img) => img.id !== image.id
            );

            // Добавляем новое изображение
            return [...filteredImages, newImage];
          });

          onClose();
          setPrelouder(false);
        }
      } else {
        const res = await dispatch(updateImageSlice(imageData));
        if (res.payload.status === 200) {
          const resEdit = market
            ? await dispatch(applicationsAdminEdit(id))
            : await dispatch(lotsEdit(id));
          if (resEdit.payload.status === 200) {
            setSortedList(
              market
                ? resEdit.payload.data.attachments
                : resEdit.payload.data.greade.images
            );
            onClose();
            setPrelouder(false);
          }
        } else {
          console.error("Error saving image:", res);
        }
      }
    } catch (error) {
      console.error("Error during save operation:", error);
    }
  };

  const handleReset = () => {
    if (canvas && originalImage) {
      canvas.clear();
      canvas.add(originalImage);
      resetDrawing();
      setCropRect(null);
    }
  };

  const rotateImage = (angle) => {
    if (originalImage && canvas) {
      originalImage.rotate((originalImage.angle + angle) % 360);
      canvas.renderAll();
    }
  };

  // Обработчик для создания прямоугольника кадрирования
  const addCropRect = () => {
    if (!canvas) return;

    // Удаление старого прямоугольника кадрирования
    if (cropRect) {
      canvas.remove(cropRect);
      setCropRect(null); // Сбрасываем состояние кадра
    }

    // Создание нового прямоугольника кадрирования
    const rect = new Rect({
      left: 100, // Начальная позиция
      top: 100,
      width: 200, // Начальная ширина и высота
      height: 200,
      fill: "rgba(255, 255, 255, 0.3)", // Прозрачная заливка
      stroke: "white", // Белая рамка
      strokeDashArray: [5, 5], // Пунктирная линия
      selectable: true, // Сделаем его интерактивным
      hasControls: true, // Возможность изменения размеров
      lockRotation: true, // Блокировка вращения
    });

    setCropRect(rect); // Сохранение нового прямоугольника
    canvas.add(rect); // Добавляем прямоугольник на холст
    canvas.setActiveObject(rect); // Устанавливаем его активным объектом
    canvas.renderAll(); // Перерисовка холста
    setIsCropping(true); // Включаем режим кадрирования
    setIsDraggingDisabled(true); // Отключаем перетаскивание
  };

  // Применение кадрирования
  const applyCrop = () => {
    if (!canvas || !cropRect) return;

    const { left, top, width, height } = cropRect.getBoundingRect();

    // Создаем временный холст для обрезки изображения
    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = width;
    croppedCanvas.height = height;
    const ctx = croppedCanvas.getContext("2d");

    const img = originalImage.getElement();
    const scaleX = originalImage.scaleX || 1;
    const scaleY = originalImage.scaleY || 1;

    // Кадрируем изображение
    ctx.drawImage(
      img,
      left / scaleX,
      top / scaleY,
      width / scaleX,
      height / scaleY,
      0,
      0,
      width,
      height
    );

    const dataURL = croppedCanvas.toDataURL("image/png");

    // Загрузка обрезанного изображения на холст
    const newImg = new Image();
    newImg.onload = () => {
      const newFabricImg = new FabricImage(newImg, {
        selectable: false,
        evented: false,
      });

      canvas.clear();
      canvas.setWidth(width);
      canvas.setHeight(height);
      canvas.add(newFabricImg);

      setOriginalImage(newFabricImg); // Обновляем изображение в состоянии
      setCropRect(null); // Сбрасываем кадрирование
      setIsCropping(false); // Выходим из режима кадрирования
    };

    newImg.src = dataURL;

    setIsDraggingDisabled(false); // Включаем перетаскивание
  };

  // ДЛя рисования кистью
  const toggleDrawingMode = () => {
    if (canvas) {
      const newMode = !isDrawingMode;
      setIsDrawingMode(newMode);
      canvas.isDrawingMode = newMode;
      canvas.freeDrawingBrush.color = drawingColor;
      canvas.freeDrawingBrush.width = 20;
    }
  };

  useEffect(() => {
    if (canvas) {
      canvas.freeDrawingBrush = new PencilBrush(canvas);
      canvas.freeDrawingBrush.color = drawingColor;
    }
  }, [canvas]);

  const resetPolygonState = () => {
    setPoints([]);
    setPolygon(null);
    setLine(null);
    if (startCircle) {
      canvas.remove(startCircle);
      setStartCircle(null);
    }
    setIsDrawing(false); // Отключаем режим рисования многоугольника
  };

  const resetCropState = () => {
    if (cropRect) {
      canvas.remove(cropRect); // Удаляем прямоугольник кадрирования
      setCropRect(null);
    }
    setIsCropping(false); // Отключаем режим кадрирования
    setIsDraggingDisabled(false); // Включаем возможность перетаскивания
  };

  const handleToolChange = (tool) => {
    // Сбрасываем многоугольник, если переключаемся на другой инструмент
    if (tool !== "polygon") {
      resetPolygonState();
    }

    // Сбрасываем кадрирование, если переключаемся на другой инструмент
    if (tool !== "crop") {
      resetCropState();
    }

    setDrawingTool(tool);

    // Отключаем кисть, если выбираем не кисть
    if (tool === "brush") {
      setIsDrawingMode(true);
      canvas.isDrawingMode = true;
    } else {
      setIsDrawingMode(false);
      canvas.isDrawingMode = false;
    }

    // Если выбрали кадрирование, отключаем кисть
    if (tool === "crop") {
      resetPolygonState(); // Очищаем многоугольник, если он активен
      setIsDrawingMode(false); // Отключаем кисть
      canvas.isDrawingMode = false; // Отключаем режим рисования
      addCropRect(); // Включаем режим кадрирования
    }

    if (tool === "text") {
      setIsTextMode(true); // Включаем режим текстового ввода
      canvas.isDrawingMode = false; // Отключаем режим рисования, если был включён
    } else {
      setIsTextMode(false); // Отключаем режим текстового ввода
    }

    if (tool === "circle") {
      setIsDrawingShape(true); // Включаем режим рисования сразу при выборе инструмента
    } else {
      setIsDrawingShape(false); // Отключаем режим рисования, если выбран другой инструмент
    }

    if (tool === "square") {
      setIsDrawingSquare(true); // Включаем режим рисования сразу при выборе инструмента
    } else {
      setIsDrawingSquare(false); // Отключаем режим рисования, если выбран другой инструмент
    }
  };

  // Пишем текст
  useEffect(() => {
    if (!canvas || !isTextMode) return;

    const handleCanvasClick = (opt) => {
      const pointer = canvas.getPointer(opt.e);
      const target = canvas.findTarget(opt.e);

      // Проверяем, кликнул ли пользователь на текстовом объекте
      if (target && target.type === "textbox") {
        // Если текстовый объект уже существует, активируем его для редактирования
        target.enterEditing();
        target.hiddenTextarea.focus();
      } else {
        // Если текстовый объект не найден, создаем новый
        const newText = new Textbox(text || "", {
          left: pointer.x,
          top: pointer.y,
          fontSize: 24,
          fill: drawingColor,
          editable: true, // Позволяет редактировать текст
          width: 200, // Ограничиваем ширину текстового блока
          objectCaching: false, // Объект не будет кешироваться для плавного редактирования
        });

        canvas.add(newText); // Добавляем текст на холст
        canvas.setActiveObject(newText); // Делаем его активным объектом
        canvas.renderAll();

        // Автоматически активируем редактирование текста
        newText.enterEditing();
        newText.hiddenTextarea.focus();

        setText(""); // Очищаем текст после добавления
      }
    };

    canvas.on("mouse:down", handleCanvasClick);

    // Добавляем возможность удаления текста с помощью клавиш Backspace или Delete
    const handleKeyPress = (e) => {
      if (e.key === "Backspace" || e.key === "Delete") {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === "textbox") {
          canvas.remove(activeObject);
          canvas.renderAll();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      canvas.off("mouse:down", handleCanvasClick);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [canvas, isTextMode, text, drawingColor]);
  //

  // Рисование круга
  useEffect(() => {
    if (!canvas) return;

    let isCircleDrawn = false; // Флаг для отслеживания завершения рисования круга

    const handleMouseDown = (opt) => {
      if (!isDrawingShape || isCircleDrawn) return; // Проверка на режим рисования и наличие завершённого круга
      const pointer = canvas.getPointer(opt.e);

      setStartPoint(pointer); // Запоминаем начальную точку

      const newCircle = new Circle({
        left: pointer.x,
        top: pointer.y,
        originX: "center",
        originY: "center",
        radius: 1, // Начальный радиус (будет увеличиваться при движении мыши)
        fill: drawingColor,
        selectable: false, // Пока рисуем, круг не будет интерактивным
        hasControls: false, // Не показываем контролы во время рисования
        evented: false, // Отключаем события во время рисования
      });

      setCircle(newCircle);
      canvas.add(newCircle);
    };

    const handleMouseMove = (opt) => {
      if (!circle || !startPoint) return;

      const pointer = canvas.getPointer(opt.e);

      const radius = Math.sqrt(
        Math.pow(pointer.x - startPoint.x, 2) +
          Math.pow(pointer.y - startPoint.y, 2)
      ); // Вычисляем радиус на основе расстояния от начальной точки

      circle.set({ radius });
      canvas.renderAll();
    };

    const handleMouseUp = () => {
      if (!circle) return;

      // После завершения рисования делаем круг интерактивным
      circle.set({
        selectable: true, // Включаем возможность выделения и перемещения
        hasControls: true, // Включаем возможность изменения размеров
        evented: true, // Включаем события для интерактивности
      });

      canvas.setActiveObject(circle); // Устанавливаем круг активным объектом
      canvas.renderAll(); // Перерисовываем холст

      setCircle(null); // Сбрасываем активный круг
      setStartPoint(null); // Сбрасываем начальную точку
      isCircleDrawn = true; // Флаг, что круг завершён

      setIsDrawingShape(false); // Отключаем режим рисования после завершения
    };

    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:move", handleMouseMove);
    canvas.on("mouse:up", handleMouseUp);

    return () => {
      canvas.off("mouse:down", handleMouseDown);
      canvas.off("mouse:move", handleMouseMove);
      canvas.off("mouse:up", handleMouseUp);
    };
  }, [canvas, circle, startPoint, drawingColor, isDrawingShape]);

  //

  // Рисование квадрата
  useEffect(() => {
    if (!canvas) return;

    let isSquareDrawn = false; // Флаг для отслеживания завершения рисования квадрата

    const handleMouseDown = (opt) => {
      if (!isDrawingSquare || isSquareDrawn) return; // Проверка на режим рисования и наличие завершённого квадрата
      const pointer = canvas.getPointer(opt.e);

      setStartPoint(pointer); // Запоминаем начальную точку

      const newRect = new Rect({
        left: pointer.x,
        top: pointer.y,
        originX: "left",
        originY: "top",
        width: 1, // Начальная ширина
        height: 1, // Начальная высота
        fill: drawingColor,
        selectable: false, // Пока рисуем, квадрат не будет интерактивным
        hasControls: false, // Не показывать контролы во время рисования
        evented: false, // Отключаем события во время рисования
      });

      setSquare(newRect); // Сохраняем ссылку на текущий объект квадрата
      canvas.add(newRect);
    };

    const handleMouseMove = (opt) => {
      if (!square || !startPoint) return;

      const pointer = canvas.getPointer(opt.e);

      // Рассчитываем размеры квадрата, ширина и высота всегда одинаковы
      const sideLength = Math.min(
        Math.abs(pointer.x - startPoint.x),
        Math.abs(pointer.y - startPoint.y)
      );

      // Если перемещаем вправо и вниз
      if (pointer.x > startPoint.x && pointer.y > startPoint.y) {
        square.set({
          width: sideLength,
          height: sideLength,
        });
      }
      // Если перемещаем влево и вверх
      else if (pointer.x < startPoint.x && pointer.y < startPoint.y) {
        square.set({
          left: startPoint.x - sideLength,
          top: startPoint.y - sideLength,
          width: sideLength,
          height: sideLength,
        });
      }
      // Если перемещаем вправо и вверх
      else if (pointer.x > startPoint.x && pointer.y < startPoint.y) {
        square.set({
          top: startPoint.y - sideLength,
          width: sideLength,
          height: sideLength,
        });
      }
      // Если перемещаем влево и вниз
      else if (pointer.x < startPoint.x && pointer.y > startPoint.y) {
        square.set({
          left: startPoint.x - sideLength,
          width: sideLength,
          height: sideLength,
        });
      }

      canvas.renderAll();
    };

    const handleMouseUp = () => {
      if (!square) return;

      // После завершения рисования делаем квадрат интерактивным
      square.set({
        selectable: true, // Включаем возможность выделения и перемещения
        hasControls: true, // Включаем возможность изменения размеров
        evented: true, // Включаем события для интерактивности
      });

      canvas.setActiveObject(square); // Устанавливаем квадрат активным объектом
      canvas.renderAll(); // Перерисовываем холст

      setSquare(null); // Сбрасываем активный квадрат
      setStartPoint(null); // Сбрасываем начальную точку
      isSquareDrawn = true; // Флаг, что квадрат завершён

      setIsDrawingSquare(false); // Отключаем режим рисования после завершения
    };

    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:move", handleMouseMove);
    canvas.on("mouse:up", handleMouseUp);

    return () => {
      canvas.off("mouse:down", handleMouseDown);
      canvas.off("mouse:move", handleMouseMove);
      canvas.off("mouse:up", handleMouseUp);
    };
  }, [canvas, square, startPoint, drawingColor, isDrawingSquare]);

  useEffect(() => {
    const handleDelete = (e) => {
      if (e.key === "Delete" || e.key === "Backspace") {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          canvas.remove(activeObject); // Удаление активного объекта (круга или квадрата)
          canvas.discardActiveObject(); // Сбрасываем выделение
          canvas.renderAll(); // Перерисовываем холст
        }
      }
    };

    // Добавляем событие нажатия клавиш
    document.addEventListener("keydown", handleDelete);

    return () => {
      // Удаляем событие при размонтировании компонента
      document.removeEventListener("keydown", handleDelete);
    };
  }, [canvas]);

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      ariaHideApp={false}
      contentLabel="Редактирование изображения"
      className={"image-editor-modal"}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          overflow: "auto",
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%", // Можно настроить ширину и высоту под экран
          height: "90%", // 90% от экрана
          maxHeight: "90vh", // Ограничиваем высоту вьюпорта
          maxWidth: "90vw", // Ограничиваем ширину вьюпорта
          overflow: "auto", // Добавляем скролл для контента
          zIndex: 9999,
        },
      }}
    >
      <div className="image-editor-modal">
        <div className="row">
          {prelouder ? (
            <Preloader />
          ) : (
            <>
              <div className="image-editor-modal-panel">
                <div className="controls d-flex">
                  {/* Повернуть вправо */}
                  <button
                    onClick={() => {
                      rotateImage(90);
                      setActiveButton("rotateRight");
                    }}
                    title="Повернуть вправо"
                    className={`${
                      activeButton === "rotateRight" ? "active" : ""
                    } image-editor__button`}
                  >
                    <RotateRightIcon />
                  </button>
                  {/*  */}

                  {/* Повернуть влево */}
                  <button
                    onClick={() => {
                      rotateImage(-90);
                      setActiveButton("rotateLeft");
                    }}
                    title="Повернуть влево"
                    className={`${
                      activeButton === "rotateLeft" ? "active" : ""
                    } image-editor__button`}
                  >
                    <RotateLeftIcon />
                  </button>
                  {/*  */}

                  {/* Кисть для рисования */}
                  <button
                    onClick={() => {
                      handleToolChange("brush ");
                      toggleDrawingMode();
                      setActiveButton("brush");
                    }}
                    title="Кисть"
                    className={`${
                      activeButton === "brush" ? "leave-active" : ""
                    } image-editor__button`}
                  >
                    <BrushIcon />
                  </button>
                  {/*  */}

                  {/* По точечное закрашивание */}
                  <button
                    onClick={() => {
                      setIsDrawing(true);
                      setActiveButton("polygon");
                      handleToolChange("polygon");
                    }}
                    title="Кисть"
                    className={`${
                      activeButton === "polygon" ? "leave-active" : ""
                    } image-editor__button`}
                  >
                    <TransformIcon />
                  </button>
                  {/*  */}

                  {/* Кадрировать */}
                  <button
                    onClick={() => {
                      setActiveButton("crop");
                      setIsCropping(true);
                      handleToolChange("crop");
                    }}
                    title="Кадрировать"
                    className={`${
                      activeButton === "crop" ? "leave-active" : ""
                    } image-editor__button`}
                  >
                    <CropIcon />
                  </button>
                  {/*  */}

                  {/* Текст */}
                  <button
                    onClick={() => {
                      setIsTextMode(true);
                      setActiveButton("text");
                    }}
                    title="Текст"
                    className={`${
                      activeButton === "text" ? "leave-active" : ""
                    } image-editor__button`}
                  >
                    <TextIcon />
                  </button>
                  {/*  */}

                  {/* Круг */}
                  <button
                    onClick={() => {
                      handleToolChange("circle");
                      setActiveButton("circle");
                    }}
                    title="Круг"
                    className={`${
                      activeButton === "circle" ? "leave-active" : ""
                    } image-editor__button`}
                  >
                    <CircleIcon />
                  </button>
                  {/*  */}

                  {/* Квадрат  */}
                  <button
                    onClick={() => {
                      handleToolChange("square");
                      setActiveButton("square");
                    }}
                    title="Квадрат"
                    className={`${
                      activeButton === "square" ? "leave-active" : ""
                    } image-editor__button`}
                  >
                    <SquareIcon />
                  </button>
                  {/*  */}

                  {/* Выбор цвета */}
                  <div className="color-picker-container">
                    <input
                      type="color"
                      id="colorPicker"
                      value={drawingColor}
                      onChange={(e) => setDrawingColor(e.target.value)}
                    />
                    <label
                      htmlFor="colorPicker"
                      className="color-picker-label"
                      style={{ backgroundColor: drawingColor }} // Устанавливаем фон label в выбранный цвет
                    >
                      Выбор цвета
                    </label>
                  </div>
                  {/*  */}

                  <div className="ml-auto d-flex">
                    {/* Применить  */}
                    {isCropping && (
                      <button
                        onClick={applyCrop}
                        title="Применить кадрирование"
                        className="active apply-active__footer "
                      >
                        Применить
                      </button>
                    )}
                    {/*  */}

                    {/* Отмена */}
                    <button
                      onClick={() => {
                        handleReset();
                        setActiveButton("reset");
                      }}
                      title="Отмена"
                      className={`${
                        activeButton === "reset" ? "active" : ""
                      } cancel-active__footer `}
                    >
                      Отменить
                    </button>
                    {/*  */}

                    {/* Закрыть  */}
                    <button
                      type="button"
                      className="close-active__footer"
                      onClick={onClose}
                    >
                      Закрыть без сохранения
                    </button>
                    {/*  */}

                    {/* Сохранить  */}
                    <button
                      onClick={handleSave}
                      title="Сохранить изменения"
                      disabled={isLoading}
                      className="save-active__footer"
                    >
                      Сохранить и закрыть
                    </button>
                    {/*  */}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <canvas
                  ref={initCanvas}
                  width={800}
                  height={600}
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseMove={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                  style={{ display: "block" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ImageEditorModal;
