import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { lawStore } from "./../../../store/law/lawStoreSlice/lawStoreSlice";

function Entity() {
  const [law, setLaw] = useState({});
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setLaw({ ...law, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(lawStore(law)).then((res) => {
      if (res.payload.status === 200) {
        toast.success("Успешно сохранено , ожидайте подтвеждение!");
      } else {
        toast.error("Произошла ошибка!");
      }
    });
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="tab-item settings__row">
        <div className="settings__col">
          <div className="settings__item">
            <div className="settings__item-title">Данные о юр. лице</div>
            <div className="settings__item-text">
              Укажите информацию о своём юр. лице.
            </div>
            <div className="input-tow-col">
              <div className="input-wrap">
                <span>Наименование организации</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="title"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrap">
                <span>ИНН</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="legal_inn"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrap">
                <span>Юридический адрес</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="legal_address"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrap">
                <span>КПП</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="legal_kpp"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrap">
                <span>Банк</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="legal_bank_name"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrap">
                <span>БИК</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="legal_bik"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrap">
                <span>Контактное лицо</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="legal_contact_person"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrap">
                <span>Расчётный счёт</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="legal_checking_account"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrap">
                <span>Контактный телефон</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="legal_contact_phone"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrap">
                <span>Кор. счёт</span>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Не указано"
                  name="legal_correspondent_account"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <button type="submit" className="gradient-btn-blue">
          Сохранить данные
        </button>
      </div>
    </form>
  );
}

export default Entity;
