import axios from "axios";

const API_URL = "/v1/send_telegram";

// telegramSend
const telegramSend = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//

const telegramService = {
  telegramSend,
};

export default telegramService;
