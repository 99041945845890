import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { userPush } from "../../../store/admin/users/userSendPushSlice/userSendPushSlice";

function AdminUserPush({ active, onClick, user }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setFormData({ ...formData, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(userPush({ id: user.id, data: formData })).then((res) => {
      if (res?.payload?.status === 200) {
        onClick();
        toast.success("Ваше уведомление успешно отправлено!");
      } else {
        toast.error("Ваше уведомление не отправлено! Попробуйте еще раз !");
      }
    });
  };
  return (
    <div className={`main__sidebar notif-bar ${active ? "active" : ""}`}>
      <div className="main__filter-head pb-0">
        <span className="main__filter-title"> Push-уведомление</span>
        <button type="button" className="close" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.9398 8L13.1398 3.80667C13.2653 3.68113 13.3359 3.51087 13.3359 3.33333C13.3359 3.1558 13.2653 2.98554 13.1398 2.86C13.0143 2.73447 12.844 2.66394 12.6665 2.66394C12.4889 2.66394 12.3187 2.73447 12.1931 2.86L7.9998 7.06L3.80646 2.86C3.68093 2.73447 3.51066 2.66394 3.33313 2.66394C3.1556 2.66394 2.98533 2.73447 2.8598 2.86C2.73426 2.98554 2.66374 3.1558 2.66374 3.33333C2.66374 3.51087 2.73426 3.68113 2.8598 3.80667L7.0598 8L2.8598 12.1933C2.79731 12.2553 2.74771 12.329 2.71387 12.4103C2.68002 12.4915 2.6626 12.5787 2.6626 12.6667C2.6626 12.7547 2.68002 12.8418 2.71387 12.9231C2.74771 13.0043 2.79731 13.078 2.8598 13.14C2.92177 13.2025 2.99551 13.2521 3.07675 13.2859C3.15798 13.3198 3.24512 13.3372 3.33313 13.3372C3.42114 13.3372 3.50827 13.3198 3.58951 13.2859C3.67075 13.2521 3.74449 13.2025 3.80646 13.14L7.9998 8.94L12.1931 13.14C12.2551 13.2025 12.3288 13.2521 12.4101 13.2859C12.4913 13.3198 12.5785 13.3372 12.6665 13.3372C12.7545 13.3372 12.8416 13.3198 12.9228 13.2859C13.0041 13.2521 13.0778 13.2025 13.1398 13.14C13.2023 13.078 13.2519 13.0043 13.2857 12.9231C13.3196 12.8418 13.337 12.7547 13.337 12.6667C13.337 12.5787 13.3196 12.4915 13.2857 12.4103C13.2519 12.329 13.2023 12.2553 13.1398 12.1933L8.9398 8Z"
              fill="#8D8796"
            />
          </svg>
        </button>
      </div>
      <div className="seller-modal__repeat-price-time ">
        {user.phone} {user.name}
      </div>

      <form className="w-100" onSubmit={onSubmit}>
        <div class="input-wrap mt-3 w-100 ml-0">
          <span>Заголовок</span>
          <input
            type="text"
            name="notifications__heading"
            class="input-style "
            placeholder="Не указано"
            onChange={handleChange}
            required
          />
        </div>

        <div class="w-100 mt-3 input-wrap ml-0">
          <span>Текст уведомления</span>
          <textarea
            rows="3"
            name="notifications__description"
            placeholder="Не указано"
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button className="gradient-btn-blue w-100 mt-auto close-btn">
          Отправить
        </button>
      </form>
    </div>
  );
}

export default AdminUserPush;
