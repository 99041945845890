import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AnimatedPage from "../../components/animated/animated";
import AuthForm from "../../components/auth-form/auth-form";
import ButtonWithBackground from "../../components/button/button-with-background";
import { resetPasswordVerifyPost } from "../../store/auth/reset-password/resetPasswordVerifySlice";

function ResetPasswordVerify() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [countryCode, setCountryCode] = useState("ru"); // Код страны
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem("web_vinz");

  useEffect(() => {
    if (user !== null) {
      navigate("/setting");
    }
  }, [user]);

  const result = useSelector((state) => state.reserPasswordVerify);

  // Маски для различных стран
  const maskMap = {
    ru: "+9 (999) 999-99-99",
    kg: "+999 999 999 999",
  };

  // Регулярные выражения для валидации телефонов в зависимости от страны
  const phonePatternMap = {
    ru: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
    kg: /^\+996 \d{3} \d{3} \d{3}$/,
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    // Проверка телефона на основе выбранного кода страны
    const currentPattern = phonePatternMap[countryCode];
    if (!currentPattern.test(value)) {
      setPhoneNumberError("Неправильный формат номера телефона");
    } else {
      setPhoneNumberError("");
    }
  };

  const handleCountryChange = (e) => {
    setCountryCode(e.target.value);
    setPhoneNumber(""); // Сброс номера при смене страны
    setPhoneNumberError(""); // Сброс ошибки при смене страны
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!phoneNumberError) {
      dispatch(resetPasswordVerifyPost({ phone: phoneNumber }))
        .then((response) => {
          if (response.payload.status == 200) {
            toast.success("Номер прошел регистрацию!");
            navigate("/reset-password");
          } else {
            toast.error("Произошла ошибка!");
          }
        })
        .catch((error) => {
          toast.error("Произошла ошибка!");
        });
    }
  };

  return (
    <AnimatedPage>
      <AuthForm
        title="Восстановление пароля"
        text="Уже есть аккаунт"
        link="/login"
        repeatSms={"resetPassword"}
      >
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            {/* Селектор для смены страны */}
            <select
              value={countryCode}
              onChange={handleCountryChange}
              className="form-select"
            >
              <option value="ru">Россия</option>
              <option value="kg">Кыргызстан</option>
            </select>
          </div>
          <div className="position-relative">
            <ReactInputMask
              mask={maskMap[countryCode]} // Динамическая маска в зависимости от страны
              maskChar="_"
              name="phone"
              className="mb-3 mt-4"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder={
                countryCode === "ru" ? "+7 (___) ___-__-__" : "+996 ___ ___ ___"
              }
              required
            />
            {phoneNumberError && (
              <p className="error-message">{phoneNumberError}</p>
            )}
          </div>
          <ButtonWithBackground>
            {result && result?.isLoading ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Идет проверка...</span>
              </>
            ) : (
              "Восстановить"
            )}
          </ButtonWithBackground>
        </form>
      </AuthForm>
    </AnimatedPage>
  );
}

export default ResetPasswordVerify;
