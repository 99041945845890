import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { cityGet } from "../../store/city/cityGetSlice/cityGetSlice";
import { citySearch } from "../../store/city/citySearchSlice/citySearchSlice";
import { customNoOptionsMessage } from "../../utils/option-not-found";
import { selectCity } from "../../utils/select-array";
import selectStyle from "../../utils/select-styles";

function SearchCity({
  onChangeCity,
  name,
  createData,
  isButtonDisabled,
  title,
}) {
  const dispatch = useDispatch();
  const [inputLength, setInputLength] = useState(0);
  const [inputCity, setInputCity] = useState("");
  const [citySelect, setCitySelect] = useState({});
  const [isInitialCreateData, setIsInitialCreateData] = useState(true);

  const topCitiesData = [
    { id: 45470, text: "Москва" },
    { id: 43754, text: "Санкт-Петербург" },
    { id: 58528, text: "Новосибирск" },
    { id: 81259, text: "Екатеринбург" },
    { id: 52350, text: "Нижний Новгород" },
    { id: 8346, text: "Казань" },
    { id: 100951, text: "Челябинск" },
    { id: 59684, text: "Омск" },
    { id: 77561, text: "Самара" },
    { id: 73179, text: "Ростов-на-Дону" },
    { id: 1473, text: "Уфа" },
    { id: 13107, text: "Красноярск" },
    { id: 10239, text: "Воронеж" },
    { id: 66088, text: "Пермь" },
    { id: 24775, text: "Волгоград" },
    { id: 12529, text: "Краснодар" },
    { id: 79913, text: "Саратов" },
    { id: 99758, text: "Тюмень" },
    { id: 78287, text: "Тольятти" },
    { id: 10447, text: "Курск" },
  ];

  const { city } = useSelector((state) => state.city);

  useEffect(() => {
    if (isInitialCreateData && Object.keys(createData).length !== 0) {
      dispatch(cityGet({ city_id: createData[name] })).then((res) => {
        if (res.payload.status === 200) {
          setCitySelect({
            value: res.payload.data[0].id,
            label: res.payload.data[0].text,
            name: name,
          });
        }
        setIsInitialCreateData(false); // Устанавливаем флаг, чтобы больше не выполнять код
      });
    }
  }, [createData, isInitialCreateData]);

  useEffect(() => {
    if (city && Object.keys(city).length !== 0) {
      // Запись для select
      const selectedOption = city?.data.find(
        (option) => option.id === createData[name]
      );
      dispatch(citySearch({ selectedOption: selectedOption }));
      setCitySelect({
        value: selectedOption?.id,
        label: selectedOption?.text,
        name: name,
      });
      //
    }
  }, [createData.region_id]);

  const onInputChange = (value) => {
    setInputLength(value.length);
    setInputCity(value);
    if (inputLength > 2) {
      dispatch(cityGet({ city: inputCity }));
    }
  };
  const cityOptions = city ? selectCity(city?.data, name) : [];

  const handleCitySelect = (city) => {
    dispatch(cityGet({ city_id: city })).then((res) => {
      if (res.payload.status === 200) {
        setCitySelect({
          value: res.payload.data[0].id,
          label: res.payload.data[0].text,
          name: name,
        });
      }
      onChangeCity({ name: "region_id", value: city });
      setIsInitialCreateData(false); // Устанавливаем флаг, чтобы больше не выполнять код;
    });
  };

  return (
    <div
      className={`add-car-data__flex input-tow-col ${
        inputLength < 3 && inputLength !== 0 ? "requerid-select" : ""
      }`}
    >
      <div
        className={`input-wrap w-100 ${isButtonDisabled && "requerid-filed"}`}
      >
        <span>
          {title}
          <span className="red-gradient-text">*</span>
        </span>
        <Select
          className="input-style"
          options={cityOptions}
          styles={selectStyle}
          placeholder="Пожалуйста, введите хотя бы 3 символа"
          noOptionsMessage={customNoOptionsMessage}
          name={name}
          value={citySelect}
          defaultValue={citySelect}
          onChange={onChangeCity} // Pass the selected value to the function
          onInputChange={onInputChange}
        />
      </div>
      <div className="top-cities-list d-flex ml-3 input-wrap flex-wrap w-100">
        {topCitiesData.map((city) => (
          <div class="list-radio-btn" key={city.id}>
            <label
              class="list-checkbox-btn__item"
              onClick={() => handleCitySelect(city.id)}
            >
              <input type="radio" name="filter_type" value={city.id} />
              <span>{city.text}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SearchCity;
