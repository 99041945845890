import axios from "axios";

const API_URL = "/v1/admin/settings";

// Получение данных
const settingsGet = async () => {
  const response = await axios.get(API_URL);
  return { data: response.data, status: response.status };
};
//

// Обновление данных и создание
const settingStoreUpdate = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//  

const settingService = {
  settingsGet,
  settingStoreUpdate,
};

export default settingService;
