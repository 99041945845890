import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import AdminUserPush from "../../../components/admin/users/user-push";
import Header from "../../../components/header/header";
import Preloader from "../../../components/prelouder/prelouder";
import Sidebar from "../../../components/sidebar/sidebar";
import { userDelete } from "../../../store/admin/users/userDeleteSlice/userDeleteSlice";
import { usersGet } from "../../../store/admin/users/usersGetSlice/usersGetSlice";

function AdminUsers() {
  const { users, isLoading } = useSelector((state) => state.usersGet);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [user, setUser] = useState({});
  const query = new URLSearchParams(location.search);

  // Объединенный объект queryParams для поиска, сортировки и пагинации
  const [queryParams, setQueryParams] = useState({
    page: parseInt(query.get("page") || "1", 10),
    search: query.get("search") || "",
    order: query.get("order") || "",
    sort: query.get("sort") || "asc",
  });

  useEffect(() => {
    dispatch(usersGet(queryParams));
  }, [queryParams]);

  const handlePageChange = (selectedObject) => {
    const newPage = selectedObject.selected + 1;
    updateQueryParams({ page: newPage });
  };

  const updateQueryParams = (updatedParams) => {
    setQueryParams((prev) => {
      const newParams = { ...prev, ...updatedParams };
      const searchParams = new URLSearchParams(newParams).toString();
      navigate(`?${searchParams}`, { replace: true });
      return newParams;
    });
  };

  const onDelete = (id) => {
    swal({
      title: "Вы уверены, что хотите удалить этого пользователя?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(userDelete(id)).then((res) => {
          if (res.payload.status === 200) {
            toast.success("Пользователь успешно удален!");
            dispatch(usersGet(queryParams));
          }
        });
      }
    });
  };

  const onSearch = (field) => {
    updateQueryParams({
      order: field,
      sort:
        queryParams.order === field && queryParams.sort === "asc"
          ? "desc"
          : "asc",
      page: 1, // Reset to first page on new sort
    });
  };

  const handleSearchInput = (value) => {
    updateQueryParams({ search: value, page: 1 });
  };
  return (
    <Sidebar>
      <div className="main__data">
        <Header
          title="Пользователи"
          subtitle={`${users?.data?.users?.total} пользователей`}
          user_search={true}
          setSearch={handleSearchInput} // Обновляем запрос по поиску
        >
          <Link to="/admin/users/create" className="users-add">
            +
          </Link>
        </Header>

        {isLoading ? (
          <div className="d-flex justify-content-center w-100">
            <Preloader />
          </div>
        ) : (
          <table className="users-table mt-4">
            <thead>
              <tr>
                <th>№</th>
                <th
                  onClick={() => onSearch("phone")}
                  className={queryParams.order === "phone" ? "active" : ""}
                >
                  Телефон
                </th>
                <th
                  onClick={() => onSearch("role")}
                  className={queryParams.order === "role" ? "active" : ""}
                >
                  Роль
                </th>
                <th
                  onClick={() => onSearch("name")}
                  className={queryParams.order === "name" ? "active" : ""}
                >
                  Имя
                </th>
                <th
                  onClick={() => onSearch("registr_date")}
                  className={
                    queryParams.order === "registr_date" ? "active" : ""
                  }
                >
                  Дата рег.
                </th>
                <th
                  onClick={() => onSearch("activity_date")}
                  className={
                    queryParams.order === "activity_date" ? "active" : ""
                  }
                >
                  Дата акт.
                </th>
                <th
                  onClick={() => onSearch("version")}
                  className={queryParams.order === "version" ? "active" : ""}
                >
                  Версия
                </th>
                <th
                  onClick={() => onSearch("model")}
                  className={queryParams.order === "model" ? "active" : ""}
                >
                  Модель
                </th>
                <th className={queryParams.order === "model" ? "active" : ""}>
                  firebase
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.length !== 0 && users.data.users.data.length !== 0 ? (
                users.data.users.data.map((item, index) => {
                  const itemNumber =
                    (users.data.users.current_page - 1) *
                      users.data.users.per_page +
                    index +
                    1;

                  return (
                    <tr className="styleItem" key={item.id}>
                      <td>{itemNumber}</td>
                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            className={`${item.onlain ? "onlain" : "offline"} `}
                          ></div>
                          {item.phone}
                        </div>
                      </td>
                      <td>{item?.roles[0]?.name}</td>
                      <td>{item.name}</td>
                      <td>
                        {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>
                        {moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>{item.version_app}</td>
                      <td>{item.model_app}</td>
                      <td>
                        {item.firebase_tokens.length !== 0
                          ? item?.firebase_tokens[0]?.firebase?.slice(0, 5)
                          : "Нет токена"}
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <div
                            className="me-3 cursor-pointer"
                            onClick={() => {
                              setActive(!active);
                              setUser(item);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="15"
                              viewBox="0 0 12 15"
                              fill="none"
                            >
                              <path
                                d="M10.5 8.385V6C10.4989 4.93734 10.1218 3.90935 9.43546 3.09808C8.74911 2.28681 7.79779 1.74461 6.75 1.5675V0.75C6.75 0.551088 6.67098 0.360322 6.53033 0.21967C6.38968 0.0790176 6.19891 0 6 0C5.80109 0 5.61032 0.0790176 5.46967 0.21967C5.32902 0.360322 5.25 0.551088 5.25 0.75V1.5675C4.2022 1.74461 3.25089 2.28681 2.56454 3.09808C1.87818 3.90935 1.50108 4.93734 1.5 6V8.385C1.06234 8.53973 0.683249 8.82605 0.414704 9.2047C0.146159 9.58335 0.00130463 10.0358 0 10.5V12C0 12.1989 0.0790178 12.3897 0.21967 12.5303C0.360322 12.671 0.551088 12.75 0.75 12.75H3.105C3.27771 13.3855 3.65475 13.9466 4.17795 14.3466C4.70114 14.7465 5.34142 14.9633 6 14.9633C6.65858 14.9633 7.29886 14.7465 7.82205 14.3466C8.34525 13.9466 8.72229 13.3855 8.895 12.75H11.25C11.4489 12.75 11.6397 12.671 11.7803 12.5303C11.921 12.3897 12 12.1989 12 12V10.5C11.9987 10.0358 11.8538 9.58335 11.5853 9.2047C11.3168 8.82605 10.9377 8.53973 10.5 8.385ZM3 6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3C6.79565 3 7.55871 3.31607 8.12132 3.87868C8.68393 4.44129 9 5.20435 9 6V8.25H3V6ZM6 13.5C5.73823 13.4984 5.48143 13.4284 5.25512 13.2968C5.02882 13.1652 4.84088 12.9767 4.71 12.75H7.29C7.15912 12.9767 6.97118 13.1652 6.74488 13.2968C6.51857 13.4284 6.26177 13.4984 6 13.5ZM10.5 11.25H1.5V10.5C1.5 10.3011 1.57902 10.1103 1.71967 9.96967C1.86032 9.82902 2.05109 9.75 2.25 9.75H9.75C9.94891 9.75 10.1397 9.82902 10.2803 9.96967C10.421 10.1103 10.5 10.3011 10.5 10.5V11.25Z"
                                fill="#8D8796"
                              />
                            </svg>
                          </div>
                          <div className="me-3">
                            <Link to={`/admin/users/edit/${item.id}`}>
                              <svg
                                width="13"
                                height="14"
                                viewBox="0 0 13 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.75 4.02501C12.7505 3.94276 12.7347 3.86122 12.7036 3.78507C12.6725 3.70892 12.6267 3.63966 12.5687 3.58126L9.91875 0.93126C9.86034 0.873335 9.79109 0.827506 9.71494 0.796403C9.63879 0.765301 9.55725 0.749535 9.475 0.75001C9.39274 0.749535 9.3112 0.765301 9.23505 0.796403C9.15891 0.827506 9.08965 0.873335 9.03125 0.93126L7.2625 2.70001L0.431245 9.53126C0.373319 9.58966 0.327491 9.65892 0.296388 9.73507C0.265285 9.81122 0.24952 9.89276 0.249995 9.97501V12.625C0.249995 12.7908 0.315843 12.9497 0.433053 13.067C0.550264 13.1842 0.709235 13.25 0.874995 13.25H3.525C3.61245 13.2548 3.69993 13.2411 3.78176 13.2099C3.86359 13.1786 3.93794 13.1306 4 13.0688L10.7937 6.23751L12.5687 4.50001C12.6258 4.43944 12.6723 4.36972 12.7062 4.29376C12.7123 4.24394 12.7123 4.19358 12.7062 4.14376C12.7092 4.11467 12.7092 4.08535 12.7062 4.05626L12.75 4.02501ZM3.26875 12H1.5V10.2313L7.70625 4.02501L9.475 5.79376L3.26875 12ZM10.3562 4.91251L8.5875 3.14376L9.475 2.26251L11.2375 4.02501L10.3562 4.91251Z"
                                  fill="#8D8796"
                                />
                              </svg>
                            </Link>
                          </div>
                          <div
                            className="me-1 cursor-pointer"
                            onClick={() => onDelete(item.id)}
                          >
                            <svg
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.5 3.25H9V2.625C9 2.12772 8.80246 1.65081 8.45083 1.29917C8.09919 0.947544 7.62228 0.75 7.125 0.75H5.875C5.37772 0.75 4.90081 0.947544 4.54917 1.29917C4.19754 1.65081 4 2.12772 4 2.625V3.25H1.5C1.33424 3.25 1.17527 3.31585 1.05806 3.43306C0.940848 3.55027 0.875 3.70924 0.875 3.875C0.875 4.04076 0.940848 4.19973 1.05806 4.31694C1.17527 4.43415 1.33424 4.5 1.5 4.5H2.125V11.375C2.125 11.8723 2.32254 12.3492 2.67417 12.7008C3.02581 13.0525 3.50272 13.25 4 13.25H9C9.49728 13.25 9.97419 13.0525 10.3258 12.7008C10.6775 12.3492 10.875 11.8723 10.875 11.375V4.5H11.5C11.6658 4.5 11.8247 4.43415 11.9419 4.31694C12.0592 4.19973 12.125 4.04076 12.125 3.875C12.125 3.70924 12.0592 3.55027 11.9419 3.43306C11.8247 3.31585 11.6658 3.25 11.5 3.25ZM5.25 2.625C5.25 2.45924 5.31585 2.30027 5.43306 2.18306C5.55027 2.06585 5.70924 2 5.875 2H7.125C7.29076 2 7.44973 2.06585 7.56694 2.18306C7.68415 2.30027 7.75 2.45924 7.75 2.625V3.25H5.25V2.625ZM9.625 11.375C9.625 11.5408 9.55915 11.6997 9.44194 11.8169C9.32473 11.9342 9.16576 12 9 12H4C3.83424 12 3.67527 11.9342 3.55806 11.8169C3.44085 11.6997 3.375 11.5408 3.375 11.375V4.5H9.625V11.375Z"
                                fill="url(#paint0_linear_2179_5152)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_2179_5152"
                                  x1="1.76"
                                  y1="0.75001"
                                  x2="11.8017"
                                  y2="1.01312"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#FF6C6C" />
                                  <stop offset="1" stopColor="#F15151" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="text-left mt-1">Ничего не найдено!</div>
              )}
            </tbody>
          </table>
        )}
        <div className="mt-3">
          <ReactPaginate
            pageCount={users?.data?.users?.last_page}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            breakClassName={"pagination__item"}
            nextLinkClassName={"pagination__link"}
            nextLabel={`>`}
            previousLabel={`<`}
            pageClassName={"pagination__item"}
            disabledClassNae={"disabled"}
            activeClassName={"active"}
            forcePage={queryParams.page - 1}
          />
        </div>
        {active && (
          <AdminUserPush
            active={active}
            onClick={() => setActive(!active)}
            user={user}
          />
        )}
      </div>
    </Sidebar>
  );
}

export default AdminUsers;
