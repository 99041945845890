import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SendMessageForm from "./send-message-form";

const MessageList = ({ chats, postChatMessage, isLoading, status_id }) => {
  const messagesEndRef = useRef(null);
  const { user } = useSelector((state) => state.profileGet);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const isManagerAdmin =
    user?.data?.role === "Admin" || user?.data?.role === "Manager";
  const chats_status = useSelector((state) => state.chats);

  return (
    <>
      <div className="chat-body">
        {chats &&
          [...chats].reverse().map((message, index) => (
            <>
              {message.sender_user_id == user?.data?.user[0]?.id ? (
                <div className="message sent">
                  <div className="message-content">
                    <div className="message-header">
                      <span className="message-sender">ВЫ</span>
                      <span className="message-sender__role">
                        {message.role}
                      </span>
                    </div>
                    <p className="message-text">{message.content}</p>
                    <span className="message-timestamp">
                      {message.created_at}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="message received">
                  <div className="message-content">
                    <div className="message-header">
                      <span className="message-sender">
                        {isManagerAdmin ? (
                          <Link
                            // to={`/profile/${chats.data.chat_creator_id}`}
                            target="_blank"
                          >
                            {message.name != "" ? message.name : message.phone}
                          </Link>
                        ) : (
                          <>
                            {message.name != "" ? message.name : message.phone}
                          </>
                        )}
                      </span>
                      <span className="message-sender__role">
                        {message.role}
                      </span>
                    </div>
                    <p className="message-text">{message.content}</p>
                    <span className="message-timestamp">
                      {message.created_at}
                    </span>
                  </div>
                </div>
              )}
            </>
          ))}
        <div ref={messagesEndRef} />
      </div>
      {status_id !== 4 ? (
        <SendMessageForm
          postChatMessage={postChatMessage}
          isLoading={isLoading}
        />
      ) : (
        <div className="chat__header--title text-center">
          <div>Авто снято с продажи</div>
        </div>
      )}
    </>
  );
};

export default MessageList;
