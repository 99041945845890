import axios from "axios";

const API_URL = "/v1/admin/notification";

// Получение данных
const notificationGet = async () => {
  const response = await axios.get(API_URL);
  return { data: response.data, status: response.status };
};
//

// Обновление данных и создание
const notificationPost = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//

const notificationService = {
  notificationGet,
  notificationPost,
};

export default notificationService;
