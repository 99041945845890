import { configureStore } from "@reduxjs/toolkit";
import applicationAdminEditSlice from "./admin/application/applicationAdminEditSlice/applicationAdminEditSlice";
import applicationAdminStoreSlice from "./admin/application/applicationAdminStoreSlice/applicationAdminStoreSlice";
import updateSlice from "./admin/application/updateSlice/updateSlice";
import notificationGetSlice from "./admin/notification/notificationGetSlice/notificationGetSlice";
import notificationPostSlice from "./admin/notification/notificationPostSlice/notificationPostSlice";
import settingGetSlice from "./admin/setting/settingGetSlice/settingGetSlice";
import settingPostSlice from "./admin/setting/settingPostSlice/settingPostSlice";
import statusesGetSlice from "./admin/statuses/statusesGetSlice/statusesGetSlice";
import userCreateGetSlice from "./admin/users/userCreateGetSlice/userCreateGetSlice";
import userEditSlice from "./admin/users/userEditSlice/userEditSlice";
import usersGetSlice from "./admin/users/usersGetSlice/usersGetSlice";
import userStoreSlice from "./admin/users/userStoreSlice/userStoreSlice";
import applicationBrandSlice from "./applications-select/application-brand/applicationBrandSlice";
import applicationGenerationsSlice from "./applications-select/application-generations/applicationGenerationsSlice";
import applicationModelSlice from "./applications-select/application-model/applicationModelSlice";
import applicationSeriesSlice from "./applications-select/application-series/applicationSeriesSlice";
import applicationTypeSlice from "./applications-select/application-type/applicationTypeSlice";
import applicationYearSlice from "./applications-select/application-year/applicationYearSlice";
import applicationEnginesSlice from "./applications-select/applications-engines/applicationEnginesSlice";
import applicationGearsSlice from "./applications-select/applications-gears/applicationGearsSlice";
import applicationModificationsSlice from "./applications-select/applications-modification/applicationModificationsSlice";
import applicationTransmissionsSlice from "./applications-select/applications-transmission/applicationTransmissionsSlice";
import applicationsGarageSlice from "./applications/applicationsGarageSlice/applicationsGarageSlice";
import applicationsGetSlice from "./applications/applicationsGetSlice/applicationsGetSlice";
import applicationsGuestSlice from "./applications/applicationsGuest/applicationsGuestSlice";
import applicationsGuestSingleSlice from "./applications/applicationsGuestSingleSlice/applicationsGuestSingleSlice";
import applicationsSingleSlice from "./applications/applicationsSingleSlice/applicationsSingleSlice";
import bargainingPriceSlice from "./auction/bargainingPriceSlice/bargainingPriceSlice";
import bargainingSlice from "./auction/bargainingSlice/bargainingSlice";
import greadeSlice from "./auction/greadeSlice/greadeSlice";
import singleAuctionSlice from "./auction/singleAuctionSlice/singleAuctionSlice";
import loginSlice from "./auth/login/loginSlice";
import registerSlice from "./auth/register/registerSlice";
import resetPasswordVerifySlice from "./auth/reset-password/resetPasswordVerifySlice";
import verificationSlice from "./auth/verification/verificationSlice";
import chatListMenegerSingleSlice from "./chat/chatListMenegerSingleSlice/chatListMenegerSingleSlice";
import chatListMenegerSlice from "./chat/chatListMenegerSlice/chatListMenegerSlice";
import chatListSlice from "./chat/chatListSlice/chatListSlice";
import chatIndexSlice from "./chat/сhatIndexSlice/chatIndexSlice";
import сhatManagerSendSlice from "./chat/сhatManagerSendSlice/сhatManagerSendSlice";
import сhatSendSlice from "./chat/сhatSendSlice/сhatSendSlice";
import cityGetSlice from "./city/cityGetSlice/cityGetSlice";
import emailVerificationSlice from "./email-verification/emailVerificationSlice/emailVerificationSlice";
import filterIndexSlice from "./filter/filterIndexSlice/filterIndexSlice";
import filterQuerySlice from "./filter/filterQuerySlice/filterQuerySlice";
import headerAdaptiveSlice from "./header/header-adaptive/headerAdaptiveSlice";
import headerStyckySlice from "./header/header-sticky/headerStickySlice";
import imageTemperarySlice from "./image/image-temperaty/imageTemperarySlice";
import imageUpdate from "./image/image-update/imageUpdate";
import companiesIndexSlice from "./insurance-companies/insuranceCompaniesIndexSlice/insuranceCompaniesIndexSlice";
import logShowSlice from "./logs/logShowSlice/logShowSlice";
import markSearchSlice from "./mark-search/markSearchSlice/markSearchSlice";
import notificationsSlice from "./notifications/notificationsSlice/notificationsSlice";
import seenSlice from "./notifications/seenSlice/seenSlice";
import organizationSlice from "./organization/organizationSlice/organizationSlice";
import proSlice from "./pro/proSlice/proSlice";
import profileGetSlice from "./profile/profileGetSlice/profileGetSlice";
import profileUpdateSlice from "./profile/profileUpdateSlice/profileUpdateSlice";
import QueryApplicationSlice from "./query/applicationQuerySlice";
import rateStoreSlice from "./rate/rateStoreSlice/rateStoreSlice";
import lotsCreateSlice from "./seller/lotsCreateSlice/lotsCreateSlice";
import lotsEditSlice from "./seller/lotsEditSlice/lotsEditSlice";
import lotsGetContactSlice from "./seller/lotsGetContactSlice/lotsGetContactSlice";
import lotsIndexSlice from "./seller/lotsIndexSlice/lotsIndexSlice";
import lotsRepeatSlice from "./seller/lotsRepeatSlice/lotsRepeatSlice";
import lotsUpdateSlice from "./seller/lotsUpdateSlice/lotsUpdateSlice";
import tokenImageSlice from "./token-for-image/tokenImageSlice/tokenImageSlice";

const store = configureStore({
  reducer: {
    // Auth
    register: registerSlice,
    verification: verificationSlice,
    login: loginSlice,
    reserPasswordVerify: resetPasswordVerifySlice,

    // Profile
    profileGet: profileGetSlice,
    profileUpdate: profileUpdateSlice,

    // Applications
    applicationsGet: applicationsGetSlice,
    applicationsSingle: applicationsSingleSlice,
    applicationType: applicationTypeSlice,
    applicationBrand: applicationBrandSlice,
    applicationModel: applicationModelSlice,
    applicationYear: applicationYearSlice,
    applicationGenerations: applicationGenerationsSlice,
    applicationSeries: applicationSeriesSlice,
    applicationModifications: applicationModificationsSlice,
    applicationEngines: applicationEnginesSlice,
    applicationTransmissions: applicationTransmissionsSlice,
    applicationGears: applicationGearsSlice,
    garage: applicationsGarageSlice,
    applicationsGuest: applicationsGuestSlice,
    applicationsGuestSingle: applicationsGuestSingleSlice,

    // Auction
    bargainingGet: bargainingSlice,
    singleLot: singleAuctionSlice,
    bargainingPrice: bargainingPriceSlice,
    greadeGet: greadeSlice,

    // Seller
    lotsIndex: lotsIndexSlice,
    lotsCreate: lotsCreateSlice,
    lotsEdit: lotsEditSlice,
    lotsUpdate: lotsUpdateSlice,
    lotsContact: lotsGetContactSlice,
    lotsRepeat: lotsRepeatSlice,

    // City
    city: cityGetSlice,

    // ВРЕМЕННЫЕ ИЗОБРАЖЕНИЯ
    imageTemparary: imageTemperarySlice,
    imageToken: tokenImageSlice,
    imageEditUpload: imageUpdate,

    // Chat
    chats: chatIndexSlice,
    chatsCreate: сhatSendSlice,
    listChats: chatListSlice,
    listManagerChats: chatListMenegerSlice,
    singleManagerChat: chatListMenegerSingleSlice,
    chatsCreateManager: сhatManagerSendSlice,

    // Header Adaptive
    header: headerAdaptiveSlice,
    headerStycky: headerStyckySlice,

    // Application Query
    queryApplication: QueryApplicationSlice,

    // Filter
    filter: filterIndexSlice,
    filterQuery: filterQuerySlice,

    // Rate
    rateSend: rateStoreSlice,

    // Marks search
    marksSearchList: markSearchSlice,

    // Notifications
    notifications: notificationsSlice,
    seen: seenSlice,

    // Email
    emailCode: emailVerificationSlice,

    // Organization
    organization: organizationSlice,

    // Pro
    pro: proSlice,

    // Admin
    statuses: statusesGetSlice,
    applicationAdminStore: applicationAdminStoreSlice,
    applicationAdminEdit: applicationAdminEditSlice,
    applicationAdminUpdate: updateSlice,

    usersGet: usersGetSlice,
    userCreateData: userCreateGetSlice,
    userStore: userStoreSlice,
    userEdit: userEditSlice,

    // logs
    logsList: logShowSlice,

    //
    companiesIndex: companiesIndexSlice,

    // Setting
    settingAdmin: settingGetSlice,
    settingUpdate: settingPostSlice,

    // Notification Admin
    notificationDataAdmin: notificationGetSlice,
    notificationPostAdmin: notificationPostSlice,
  },
});

export default store;
