import React from "react";

function ButtonWithBackground(props) {
  return (
    <button
      className="button-with-background submit w-100"
      type="submit"
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

export default ButtonWithBackground;
