import React from "react";

function SquareIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4998 0.666992H1.49984C0.999837 0.666992 0.666504 1.00033 0.666504 1.50033V16.5003C0.666504 17.0003 0.999837 17.3337 1.49984 17.3337H16.4998C16.9998 17.3337 17.3332 17.0003 17.3332 16.5003V1.50033C17.3332 1.00033 16.9998 0.666992 16.4998 0.666992Z"
        fill="#190D2E"
      />
    </svg>
  );
}

export default SquareIcon;
