import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { profile } from "../../../store/profile/profileGetSlice/profileGetSlice";
import { profileUpdate } from "../../../store/profile/profileUpdateSlice/profileUpdateSlice";
import ContactDetails from "./contact-details/contact-details";
import PersonalInformation from "./personal-information/personal-information";
import ProBaner from "./pro-baner/pro-baner";
import ProfileInfo from "./profile-info/profile-info";

function Verification({ user }) {
  const [data, setData] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailNumbers, setEmailNumbers] = useState([]);

  const result = useSelector((state) => state.profileUpdate);
  const dispatch = useDispatch();

  useEffect(() => {
    setData({
      ...data,
      city_id: user?.data?.user[0]?.city?.id,
    });
    setPhoneNumbers(...phoneNumbers, user?.data?.user[0]?.additional_phone);
    setEmailNumbers(...emailNumbers, user?.data?.user[0]?.additional_email);
  }, []);

  const handleChange = (e, index) => {
    if (e.target) {
      const { name, value, type, checked } = e.target;

      let newValue = value;
      if (type === "checkbox") {
        newValue = checked ? 1 : 0;
      }

      if (name === "additional_phone") {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers[index] = newValue;
        newValue = newPhoneNumbers;
        setPhoneNumbers(newPhoneNumbers);
      }

      if (name === "additional_email") {
        const newemailNumbers = [...emailNumbers];
        newemailNumbers[index] = newValue;
        newValue = newemailNumbers;
        setEmailNumbers(newemailNumbers);
      }

      setData({ ...data, [name]: newValue });
    }
    if (e) {
      if (e.organization) {
        setData({ ...data, organization: e.organization });
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(profileUpdate(data))
      .then((response) => {
        if (response.payload.status == 200) {
          toast.success("Данные успешно сохранены!");
          dispatch(profile());
          window.scrollTo(0, 0);
        } else {
          toast.error("Произошла ошибка!");
        }
      })
      .catch((error) => {
        toast.error("Произошла ошибка!");
      });
  };

  return (
    user && (
      <form onSubmit={onSubmit}>
        <div className="tab-item settings__row">
          <div className="settings__col-1">
            {/* <PersonaltType user={user} handleChange={handleChange} /> */}
            <ContactDetails
              user={user}
              handleChange={handleChange}
              phoneNumbers={phoneNumbers}
              setPhoneNumbers={setPhoneNumbers}
              emailNumbers={emailNumbers}
              setEmailNumbers={setEmailNumbers}
            />
            <PersonalInformation
              data={data}
              user={user}
              setData={setData}
              handleChange={handleChange}
            />
          </div>
          {user?.data?.role !== "Seller" && (
            <div className="settings__col-2">
              <ProBaner />
              <ProfileInfo />
            </div>
          )}
        </div>
        <div className="bottom-bar">
          <button
            type="submit"
            name="save"
            className={`gradient-btn-blue mr-3 `}
          >
            {result && result?.isLoading ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Идет проверка...</span>
              </>
            ) : (
              "Сохранить изменения"
            )}
          </button>
        </div>
      </form>
    )
  );
}

export default Verification;
