import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import Header from "../../../components/header/header";
import Sidebar from "../../../components/sidebar/sidebar";
import { notificationGet } from "../../../store/admin/notification/notificationGetSlice/notificationGetSlice";
import { notificationPost } from "../../../store/admin/notification/notificationPostSlice/notificationPostSlice";
import { model_notification } from "../../../utils/admin-notification-model";
import { customNoOptionsMessage } from "../../../utils/option-not-found";
import selectStyle from "../../../utils/select-styles";

function AdminNotifications() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  const { data } = useSelector((state) => state.notificationDataAdmin);
  const { isLoading } = useSelector((state) => state.notificationPostAdmin);

  useEffect(() => {
    dispatch(notificationGet());
  }, []);

  const roles = data?.data?.data?.roles || [];
  const versions = data?.data?.data?.versions || [];
  const handleInputChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(notificationPost(formData)).then((res) => {
      if (res.payload.status === 200) {
        toast.success("Сообщение успешно отправлено!");
        // setFormData({
        //   notifications_heading: "",
        //   notifications_description: "",
        //   role: null,
        //   model: null,
        //   version: null,
        // });
      } else {
        toast.error("Произошла ошибка попробуйте позже ");
      }
    });
  };

  return (
    <Sidebar>
      <div className="main__data">
        <Header title="Уведомления" />
        <div className="settings__item w-100 mt-4">
          <div className="d-flex pb-3">
            <div className="w-25 input-wrap  ml-0">
              <span>Роль</span>
              <Select
                className="input-style mt-3"
                noOptionsMessage={customNoOptionsMessage}
                options={roles}
                styles={selectStyle}
                placeholder="Выберите время"
                name="special_time"
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="w-25 input-wrap  ml-0">
              <span>Модель</span>
              <Select
                className="input-style mt-3"
                noOptionsMessage={customNoOptionsMessage}
                options={model_notification}
                styles={selectStyle}
                placeholder="Выберите время"
                name="special_time"
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="w-25 input-wrap  ml-0">
              <span>Версия</span>
              <Select
                className="input-style mt-3"
                noOptionsMessage={customNoOptionsMessage}
                options={versions}
                styles={selectStyle}
                placeholder="Выберите время"
                name="special_time"
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="settings__item-title">Личная информация</div>
          <form onSubmit={onSubmit}>
            {/* Имя */}
            <div className="input-wrap w-100 ml-0">
              <span>Заголовок</span>
              <input
                type="text"
                name="notifications_heading"
                className="input-style "
                placeholder="Не указано"
                onChange={handleInputChange}
                required
              />
            </div>
            {/*  */}
            <div className="input-wrap w-100 ml-0">
              <span> Текст уведомления</span>
              <textarea
                rows="3"
                name="notifications_description"
                placeholder="Не указано"
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <button
              type="submit"
              name="save"
              className={`gradient-btn-blue admin-btn-padding p-3`}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Идет проверка...</span>
                </>
              ) : (
                "Сохранить"
              )}
            </button>
          </form>
        </div>
      </div>
    </Sidebar>
  );
}

export default AdminNotifications;
