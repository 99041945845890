import "photoswipe/dist/photoswipe.css";
import React, { useEffect, useState } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import noimage from "../../images/no-image.png";

function SliderImage({ item }) {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    lazyLoad: "ondemand", // Оставляем ленивую загрузку
    slidesToScroll: 1,
    beforeChange: (current, next) => setactiveSlide(next + 1),
  };
  const [activeSlide, setactiveSlide] = useState(1);
  const { user } = useSelector((state) => state.profileGet);

  const formatTimeDifference = (endTime) => {
    const now = new Date();
    const end = new Date(endTime);
    const diff = end - now;
    if (diff <= 0) {
      return "0д 0ч 0м";
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / 1000 / 60) % 60);

    return `${days}д ${hours}ч ${minutes}м`;
  };

  const [timeRemaining, setTimeRemaining] = useState(
    formatTimeDifference(item?.end_auction)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(formatTimeDifference(item?.end_auction));
    }, 60000); // Обновлять каждую минуту

    return () => clearInterval(timer); // Очистить интервал при размонтировании
  }, [item?.end_auction]);

  return (
    <>
      <div className="card__wrap-img">
        <div className="car-slide">
          {item.attachments && item.attachments.length !== 0 ? (
            <>
              <Slider {...settings}>
                {item.attachments.map((attachment, index) => (
                  <div key={index}>
                    <img
                      src={attachment.url}
                      alt={`Slide ${index + 1}`}
                      className="card__img"
                    />
                  </div>
                ))}
              </Slider>

              {/* Галерея для всех изображений */}
              <Gallery>
                {item.attachments.map((attachment, index) => (
                  <Item
                    original={attachment.url}
                    thumbnail={attachment.thumbnail_url}
                    width="1024"
                    height="768"
                    key={index}
                  >
                    {({ ref, open }) => (
                      <span className="ligtbox-item" ref={ref} onClick={open} />
                    )}
                  </Item>
                ))}
              </Gallery>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <img
                src={noimage}
                className="car-row__image"
                alt="No image available"
              />
            </div>
          )}
        </div>

        <span className="card__img-count">
          <span>{activeSlide}</span>/
          {item.attachments
            ? item.attachments.length !== 0
              ? item.attachments.length
              : 1
            : 1}
        </span>

        {/* Статусы Аукциона  */}
        {item?.status_id === 4 && (
          <span className="card__img-status card__img-status--muted">
            Черновик
          </span>
        )}
        {item?.status_id === 1 && (
          <span className="card__img-status">{timeRemaining}</span>
        )}
        {item?.status_id === 2 && item?.win && (
          <span className="card__img-status  card__img-status--blue">
            Сделки
          </span>
        )}
        {(item?.status_id === 3 ||
          (item?.status_id === 2 &&
            (item?.greade_price?.length === 0 || !item?.win))) && (
          <span className="card__img-status card__img-status--end">
            Завершено
          </span>
        )}

        {item?.status_id === 5 && (
          <span className="card__img-status card__img-status--muted">
            Снят с аукциона
          </span>
        )}

        {item?.status_id === 6 && (
          <span className="card__img-status card__img-status--blue">
            Сделки
          </span>
        )}

        {(item?.status_id === 8 || item?.status_id === 7) && (
          <span className="card__img-status card__img-status--muted">
            Архив
          </span>
        )}
        {/*  */}

        {item?.status?.code == "storage" && (
          <span className="card__img-status">SPECIAL</span>
        )}

        {user &&
          Object.keys(user).length !== 0 &&
          ["Admin", "Manager", "ManagerPRO"].includes(user?.data.role) && (
            <>
              {item?.status?.code == "draft" && (
                <span className="card__img-status card__img-status--muted">
                  Черновик
                </span>
              )}
              {item?.status?.code == "issued" && (
                <span className="card__img-status card__img-status--blue">
                  Доступно
                </span>
              )}
              {item?.status?.code == "ready-to-buy" && (
                <span className="card__img-status card__img-status--end">
                  Готов купить
                </span>
              )}
              {(item?.status?.code == "deal-fell-through" ||
                item?.status?.code == "deal-complated") && (
                <span className="card__img-status card__img-status--muted">
                  Завершено
                </span>
              )}
              {item?.status?.code == "denied-for-storage" && (
                <span className="card__img-status card__img-status--muted">
                  Архив
                </span>
              )}
              {item?.status?.code == "check-availability" && (
                <span className="card__img-status card__img-status--warning">
                  Уточнить
                </span>
              )}
            </>
          )}
      </div>
    </>
  );
}

export default SliderImage;
