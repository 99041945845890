import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import noimage from "../../../images/no-image.png";
import {
  singleAuction,
  singleAuctionReset,
} from "../../../store/auction/singleAuctionSlice/singleAuctionSlice";
import { lotsGetContact } from "../../../store/seller/lotsGetContactSlice/lotsGetContactSlice";
import { lotsIndex } from "../../../store/seller/lotsIndexSlice/lotsIndexSlice";
import { colorCatAuto } from "../../../utils/color-cat-auto";
import SellerHistoryPriceModal from "../seller-history-price-modal/seller-history-price-modal";

function SellerModal({ show, handlePrimaryClose, id, setShowWIn }) {
  const [actions, setAction] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(singleAuctionReset());
    dispatch(singleAuction(id));
  }, [show]);

  const { single } = useSelector((state) => state.singleLot);
  const lots = single?.data?.greade;

  const onSubmit = (e) => {
    e.preventDefault();
    switch (actions) {
      case 1:
        dispatch(lotsGetContact(id)).then((res) => {
          if (res.payload.status === 200) {
            dispatch(lotsIndex({ page: 1 }));
            toast.success("Отлично! Менеджер свяжится с вами!");
            handlePrimaryClose();
          }
        });
        break;
      case 2:
        handlePrimaryClose();
        navigate(`/repeat-auto/${id}`);
        break;
      case 3:
        setShowWIn(true);
        handlePrimaryClose();
        break;
    }
  };

  return (
    <>
      {/* Модальное окно для завершение аукциона */}
      <Modal
        show={show}
        onHide={handlePrimaryClose}
        className="seller-modal__repeat"
      >
        <form onSubmit={onSubmit}>
          <button
            type="button"
            className="close-modal"
            onClick={handlePrimaryClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.94004 8L13.14 3.80667C13.2656 3.68113 13.3361 3.51087 13.3361 3.33333C13.3361 3.1558 13.2656 2.98554 13.14 2.86C13.0145 2.73447 12.8442 2.66394 12.6667 2.66394C12.4892 2.66394 12.3189 2.73447 12.1934 2.86L8.00004 7.06L3.80671 2.86C3.68117 2.73447 3.51091 2.66394 3.33337 2.66394C3.15584 2.66394 2.98558 2.73447 2.86004 2.86C2.7345 2.98554 2.66398 3.1558 2.66398 3.33333C2.66398 3.51087 2.7345 3.68113 2.86004 3.80667L7.06004 8L2.86004 12.1933C2.79756 12.2553 2.74796 12.329 2.71411 12.4103C2.68027 12.4915 2.66284 12.5787 2.66284 12.6667C2.66284 12.7547 2.68027 12.8418 2.71411 12.9231C2.74796 13.0043 2.79756 13.078 2.86004 13.14C2.92202 13.2025 2.99575 13.2521 3.07699 13.2859C3.15823 13.3198 3.24537 13.3372 3.33337 13.3372C3.42138 13.3372 3.50852 13.3198 3.58976 13.2859C3.671 13.2521 3.74473 13.2025 3.80671 13.14L8.00004 8.94L12.1934 13.14C12.2553 13.2025 12.3291 13.2521 12.4103 13.2859C12.4916 13.3198 12.5787 13.3372 12.6667 13.3372C12.7547 13.3372 12.8419 13.3198 12.9231 13.2859C13.0043 13.2521 13.0781 13.2025 13.14 13.14C13.2025 13.078 13.2521 13.0043 13.286 12.9231C13.3198 12.8418 13.3372 12.7547 13.3372 12.6667C13.3372 12.5787 13.3198 12.4915 13.286 12.4103C13.2521 12.329 13.2025 12.2553 13.14 12.1933L8.94004 8Z"
                fill="#8D8796"
              />
            </svg>
          </button>
          <div className="row">
            <div className="col-lg-6 border-right">
              <div className="modal__title modal__mb-24">
                Выберите дальнейшие действия
              </div>
              <div className="seller-modal__single d-flex align-items-start">
                <div className="seller-modal__single-img">
                  {lots?.attachments.length !== 0 ? (
                    <img src={lots?.attachments[0].url} alt="" />
                  ) : (
                    <img src={noimage} className="mt-0" />
                  )}
                </div>
                <div className="seller-modal__repeat-info">
                  <Link to={`/seller/${id}`}>
                    <h3 className="seller-modal__repeat-info-title">
                      {lots?.car_title}
                    </h3>
                  </Link>
                  <div className="card__item-params">
                    <span>
                      {lots?.car_modification?.name}
                      {lots?.car_engine?.name}
                    </span>
                    <span>{lots?.car_transmission?.name}</span>
                    <span>{lots?.milage ? `${lots?.milage} км` : ""}</span>
                  </div>
                  {lots?.cat_auto && (
                    <span className={colorCatAuto(lots?.cat_auto)}>
                      <span>{lots?.cat_auto}</span>
                    </span>
                  )}
                </div>
              </div>
              <label className="select-block-item">
                <input
                  type="radio"
                  name="action"
                  hidden
                  defaultValue={1}
                  onChange={(e) => setAction(1)}
                />
                <div className="select-block">
                  <span>Продать лот</span>
                  {/* <p>
                    Здесь будет красивый текст от Даниила. Здесь будет красивый
                    текст от Даниила.
                  </p> */}
                </div>
              </label>
              <label className="select-block-item">
                <input
                  type="radio"
                  name="action"
                  defaultValue={2}
                  hidden
                  onChange={(e) => setAction(2)}
                />
                <div className="select-block">
                  <span>Разместить повторно</span>
                  {/* <p>
                    Здесь будет красивый текст от Даниила. Здесь будет красивый
                    текст от Даниила.
                  </p> */}
                </div>
              </label>
              <label className="select-block-item">
                <input
                  type="radio"
                  name="action"
                  defaultValue={3}
                  hidden
                  onChange={(e) => setAction(3)}
                />
                <div className="select-block">
                  <span>Победа на другом аукционе</span>
                  {/* <p>
                    Здесь будет красивый текст от Даниила. Здесь будет красивый
                    текст от Даниила.
                  </p> */}
                </div>
              </label>
              <button
                type="submit"
                className="gradient-btn-blue w-100 modal-btn"
              >
                Подтвердить
              </button>
            </div>

            {/* История ставок */}

            <SellerHistoryPriceModal lots={lots} />

            {/*  */}
          </div>
        </form>
      </Modal>
      {/*  */}
    </>
  );
}

export default SellerModal;
