import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import op from "../../../../images/op.svg";
import { lotDeal } from "../../../../store/seller/lotsDealsSlice/lotsDealsSlice";

function RepeatButton({ item, setShowRepeat, setId }) {
  const dispatch = useDispatch();
  const onArchive = () => {
    swal({
      title: "Вы уверены что хотите перенести машину в Архив?",
      icon: "warning",
      buttons: ["Отменить", "Подтвердить"],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(lotDeal({ status_id: 8, id: item.id })).then((res) => {
          if (res.payload.status === 200) {
            toast.success("Успешно отправлено в архив");
          }
        });
      }
    });
  };
  return (
    <div className="card__item-price-title">
      <Link
        to={`/repeat-auto/${item.id}`}
        className="blue-gradient-text cursor-pointer repeat-btn d-flex align-items-baseline"
      >
        Разместить повторно
      </Link>
      <div
        className="blue-gradient-text cursor-pointer repeat-btn d-flex align-items-baseline mx-3"
        onClick={onArchive}
      >
        Сделка не завершена : архив
      </div>
      {item.op_link && (
        <Link
          to={item.op_link}
          target="_blank"
          className="d-flex align-items-center card__item-price-op mx-3"
        >
          <img src={op} alt="" />
          ОП
        </Link>
      )}
    </div>
  );
}

export default RepeatButton;
