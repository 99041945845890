import { getDatabase, off, onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import left_arrow from "../../images/left-arrow.svg";
import { chatGet } from "../../store/chat/сhatIndexSlice/chatIndexSlice";
import { сhatSend } from "../../store/chat/сhatSendSlice/сhatSendSlice";
import { truncateString } from "../../utils/text-slice";
import ChatSidebar from "../chat/chat-sidebar/chat-sidebar";
import SingleGallery from "../single-gallery/single-gallery";

function Single({ single, children }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { chats, isSuccess } = useSelector((state) => state.chats);
  const [open, setOpen] = useState(false);
  const formatVIN = (vin) => {
    if (vin) {
      return `${vin.slice(0, 6)}**${vin.slice(6, 8)}${vin.slice(
        10,
        12
      )}****${vin.slice(14, 16)}`;
    }
  };

  const sendApi = (message) => {
    dispatch(сhatSend({ id: single.id, data: { content: message } })).then(
      (res) => {
        if (res.payload.status === 201) {
          dispatch(chatGet(single.id));
        }
      }
    );
  };

  const onClick = async (e, id) => {
    try {
      setOpen((prevOpen) => !prevOpen); // Один вызов setOpen

      const res = await dispatch(chatGet(single.id));
      if (res.payload.status === 200) {
        const database = getDatabase();
        const userId = user?.data.user[0].id;
        const chatRoomId = res.payload.data.chat_room_id;
        if (userId && chatRoomId) {
          const knot = `chat_room/${userId}/${chatRoomId}`;
          const messagesRef = ref(database, knot);

          const unsubscribe = onValue(messagesRef, (snapshot) => {
            if (snapshot.exists()) {
              dispatch(chatGet(single.id));
            }
          });

          // Очистка слушателя при закрытии чата
          return () => {
            off(messagesRef);
          };
        }
      }
    } catch (error) {
      console.error("Error opening chat:", error);
    }
  };

  const textWithLinks = single?.car_additional
    ? single?.car_additional?.value
        .replace(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%=~_|$?!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
          "<a href='$1' target='_blank' rel='noopener noreferrer'>$1</a>"
        )
        .replace(/\n/g, "<br />")
    : "";
  const { user } = useSelector((state) => state.profileGet);

  useEffect(() => {
    dispatch(chatGet(id));
  }, [id]);

  return (
    <div className="row detail">
      <div className="row__col-3">
        {/* Галлерея и видео авто */}
        <SingleGallery single={single} />
        {/*  */}
      </div>
      <div className="row__col-3">
        {/* Место нахождения */}
        <div className="row__item">
          <div className="row__title-14">Место нахождения</div>
          <div className="list-key-val">
            <div className="list-key-val__item d-flex justify-content-between">
              <div className="city-point">{single?.city}</div>
              <span className="text-right">
                <Link
                  to={single?.map}
                  className="xtext-btn blue-gradient-text "
                  target="_blank"
                >
                  Смотреть на карте
                </Link>
              </span>
            </div>
          </div>
        </div>
        {/*  */}
        {/* Характеристики */}
        <div className="row__item">
          <div className="row__title-14">Характеристики</div>

          <div className="list-key-val">
            <div className="list-key-val__item">
              <span>ID:</span>
              <span>{single?.id}</span>
            </div>
            {single?.characteristics?.year?.value !== "" && (
              <div className="list-key-val__item">
                <span>Год выпуска:</span>
                <span>{single?.characteristics?.year?.value}</span>
              </div>
            )}

            {single?.characteristics?.milage?.value !== "" && (
              <div className="list-key-val__item">
                <span>Пробег:</span>
                <span>{single?.characteristics?.milage?.value}</span>
              </div>
            )}

            {single?.characteristics?.series?.value !== "" && (
              <div className="list-key-val__item">
                <span>Кузов:</span>
                <span>{single?.characteristics?.series?.value}</span>
              </div>
            )}

            {single?.characteristics?.modifications_engines && (
              <div className="list-key-val__item">
                <span>Двигатель:</span>
                <span>
                  {
                    single?.characteristics?.modifications_engines
                      ?.modifications
                  }{" "}
                  / {single?.characteristics?.modifications_engines?.engines}
                </span>
              </div>
            )}

            {single?.characteristics?.transmissions?.value !== "" && (
              <div className="list-key-val__item">
                <span>Коробка передач:</span>
                <span>{single?.characteristics?.transmissions?.value}</span>
              </div>
            )}

            {single?.characteristics?.gears?.value !== "" && (
              <div className="list-key-val__item">
                <span>Привод:</span>
                <span>{single?.characteristics?.gears?.value}</span>
              </div>
            )}

            {single?.characteristics?.owners?.value !== "" && (
              <div className="list-key-val__item">
                <span>Владельцы:</span>
                <span>{single?.characteristics?.owners?.value}</span>
              </div>
            )}

            {single?.characteristics?.pts?.value !== "" && (
              <div className="list-key-val__item">
                <span>ПТС:</span>
                <span>{single?.characteristics?.pts?.value}</span>
              </div>
            )}
          </div>
        </div>
        {/*  */}

        {/* VIN */}
        <div className="row__item">
          <div className="row__title-14">VIN номер</div>
          <div className="list-key-val">
            <div className="list-key-val__item">
              <span>{formatVIN(single?.vin_number?.vin)}</span>
              <span>
                {user && Object.keys(user).length !== 0 ? (
                  <Link
                    to={single?.vin_number?.report?.value}
                    className="text-btn blue-gradient-text "
                    target="_blank"
                  >
                    {single?.vin_number?.report?.name}
                  </Link>
                ) : (
                  <Link to="/login" className="card__item-right-rate mt-auto">
                    Необходимо авторизоваться
                  </Link>
                )}
              </span>
            </div>
          </div>
        </div>
        {/*  */}

        {/* Тех. состояние */}
        <div className="row__item">
          <div className="row__title-14">Тех. состояние</div>
          <div className="list-key-val">
            {single?.auto_cat !== "" && (
              <div className="list-key-val__item">
                <span>Состояние</span>
                <span>{single?.auto_cat}</span>
              </div>
            )}
            {single?.those_condition?.starts_up?.value !== "" && (
              <div className="list-key-val__item">
                <span>Заводится</span>
                <span>{single?.those_condition?.starts_up?.value}</span>
              </div>
            )}

            {single?.those_condition?.condition_engine?.value.length !== 0 && (
              <div className="list-key-val__item">
                <span>Неисправности двигателя</span>
                <span>
                  {single?.those_condition?.condition_engine?.value.join(", ")}
                </span>
              </div>
            )}

            {single?.those_condition?.condition_transmission?.value.length !==
              0 && (
              <div className="list-key-val__item">
                <span>Неисправности КПП</span>
                <span>
                  {single?.those_condition?.condition_transmission?.value.join(
                    ", "
                  )}
                </span>
              </div>
            )}

            {single?.those_condition?.condition_electric?.value.length !==
              0 && (
              <div className="list-key-val__item">
                <span>Неисправности электрики</span>
                <span>
                  {single?.those_condition?.condition_electric?.value.join(
                    ", "
                  )}
                </span>
              </div>
            )}

            {single?.those_condition?.condition_gear?.value.length !== 0 && (
              <div className="list-key-val__item">
                <span>Неисправности ходовой</span>
                <span>
                  {single?.those_condition?.condition_gear?.value.join(", ")}
                </span>
              </div>
            )}

            {single?.those_condition?.disks?.value !== "" && (
              <div className="list-key-val__item">
                <span>Диски</span>
                <span>{single?.those_condition?.disks?.value}</span>
              </div>
            )}
            {single?.those_condition?.tires?.value !== "" && (
              <div className="list-key-val__item">
                <span>Шины</span>
                <span>{single?.those_condition?.tires?.value}</span>
              </div>
            )}

            {single?.those_condition?.tire_wire?.value !== "" && (
              <div className="list-key-val__item">
                <span>Износ</span>
                <span>{single?.those_condition?.tire_wire?.value}</span>
              </div>
            )}
            {single?.those_condition?.additionally?.value !== "" && (
              <div className="list-key-val__item">
                <span>Дополнительно</span>
                <span>{single?.those_condition?.additionally?.value}</span>
              </div>
            )}
          </div>
        </div>
        {/*  */}
      </div>
      <div className="row__col-3">
        <div className="row__item">{children}</div>
        {chats?.data !== "" && chats && (
          <div class="row__item">
            <div class="row__title-14">Чат по машине</div>
            <div class="chat-collumn-one">
              <div
                className={`chat-list-message chat-list-message-manager  d-flex align-items-center position-relative px-0`}
                onClick={(e) => {
                  onClick(e, chats?.data?.chat_room_id);
                  // setChatRoom(chats?.data?.chat_room_id);
                }}
              >
                <div className="chat-list-content">
                  <div className="chat__header">
                    <div className="chat__header--title ml-0">
                      <div>
                        {chats?.data?.chat_creator_name
                          ? chats?.data?.chat_creator_name
                          : chats?.data?.chat_creator_phone}
                      </div>
                    </div>
                  </div>

                  <div className="chat__text">
                    {truncateString(
                      chats?.data?.messages?.[0]?.content || "",
                      125
                    )}
                  </div>
                  <span className="message-time">
                    {chats?.data?.messages?.[0]?.created_at || ""}
                  </span>
                </div>
                <div className="d-flex justify-content-center chat-list-arrow">
                  {chats?.data?.unread_count !== 0 && (
                    <div className="chat-list-seen"></div>
                  )}

                  <img src={left_arrow} alt="" />
                </div>
              </div>
            </div>
          </div>
        )}

        {textWithLinks !== "" && (
          <div className="row__item">
            <div className="detail__text">
              <div className="row__title-14">Описание</div>
              <p
                dangerouslySetInnerHTML={{
                  __html: textWithLinks,
                }}
              />
              {/* <p>{dangerouslySetInnerHTML={{ __html: single?.car_additional?.value }}}</p> */}
              {/* <button type="submit" className="text-btn blue-gradient-text ">
Полное описание
</button> */}
            </div>
          </div>
        )}
      </div>
      {open && (
        <ChatSidebar
          open={open}
          id={single.id}
          chatRoom={single.chat_room}
          onClick={onClick}
          chats={chats}
          send={sendApi}
        />
      )}
    </div>
  );
}

export default Single;
