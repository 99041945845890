import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import companiesService from "../insuranceCompaniesService";

const initialState = {
  companies: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// City get

export const companiesIndex = createAsyncThunk(
  "companies/index",
  async (query, thunkAPI) => {
    try {
      return await companiesService.companiesIndex(query);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const companiesIndexSlice = createSlice({
  name: "companiesIndexSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(companiesIndex.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(companiesIndex.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.companies = action.payload;
      })
      .addCase(companiesIndex.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.companies = null;
      });
  },
});

export default companiesIndexSlice.reducer;
