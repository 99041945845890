import { useEffect, useRef, useState } from "react";
import pdf from "../../images/pdf.svg";

const FileUploadFormEdit = ({ setCreateData, createData, report, market }) => {
  const [files, setFiles] = useState(createData?.docs || ""); // Инициализация загруженными файлами, если они есть
  const [previewUrls, setPreviewUrls] = useState(
    createData?.docs || [] // Инициализация превью URL загруженными файлами
  );
  const [uploadType, setUploadType] = useState("files"); // Управление типом загрузки
  const [isLoading, setIsLoading] = useState(false); // Состояние для прелоадера
  const fileInputRef = useRef(null);

  // Обработчик выбора файлов
  const handleFileInputChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    setIsLoading(true); // Устанавливаем прелоадер

    // Создание ссылок для превью новых загруженных файлов
    const newPreviewUrls = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file); // Создание ссылки на объект файла
    });

    // Эмулируем небольшую задержку, чтобы было видно прелоадер (например, 1 секунда)
    setTimeout(() => {
      setFiles(selectedFilesArray);
      setPreviewUrls(newPreviewUrls);
      setIsLoading(false); // Отключаем прелоадер
    }, 1000); // Задержка для эмуляции времени загрузки
  };

  // Синхронизация файлов с createData при изменении
  useEffect(() => {
    if (uploadType === "files") {
      setCreateData({ ...createData, docs: files });
    }
  }, [files, uploadType]);

  // Устанавливаем тип загрузки на основе наличия report_url
  useEffect(() => {
    if (createData?.report_url) {
      setUploadType("link");
    } else {
      setUploadType("files");
    }
  }, [createData]);

  // Обработчик смены типа загрузки
  const handleUploadTypeChange = (event) => {
    const { value } = event.target;
    setUploadType(value);
    setFiles([]);
    setPreviewUrls([]);
  };

  const reportPreview = market ? report : createData?.docs?.[0]?.url;

  return (
    <>
      <h5>Автотека</h5>
      <div>
        <div className="d-flex">
          <div className="c">
            <label className="custom-radio mr-3">
              <input
                type="radio"
                name="uploadType"
                value="files"
                checked={uploadType === "files"}
                onChange={handleUploadTypeChange}
              />
              <span></span>Загрузить отчет
            </label>
            <label className="custom-radio">
              <input
                type="radio"
                name="uploadType"
                value="link"
                checked={uploadType === "link"}
                onChange={handleUploadTypeChange}
              />
              <span></span> Вставить ссылку
            </label>
          </div>
        </div>
        <div className="mt-5">
          {uploadType === "files" && (
            <>
              <input
                type="file"
                multiple
                onChange={handleFileInputChange}
                ref={fileInputRef}
                className="d-none"
                id="reports_file"
              />
              <div className="d-flex">
                <label htmlFor="reports_file" className="add-car-data__add-btn">
                  <span>Загрузить Отчет</span>
                </label>
                {/* Прелоадер при загрузке */}
                {isLoading ? (
                  <div className="spinner"></div>
                ) : (
                  <>
                    {previewUrls.length > 0
                      ? previewUrls.map((url, index) => (
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={index}
                            className="report-file"
                          >
                            <img src={pdf} alt="Preview" />
                          </a>
                        ))
                      : // Отображение загруженного ранее отчета, если он существует
                        reportPreview && (
                          <a
                            href={reportPreview}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="report-file"
                          >
                            <img src={pdf} alt="Preview" />
                          </a>
                        )}
                  </>
                )}
              </div>
            </>
          )}
          {uploadType === "link" && (
            <input
              type="text"
              onChange={(e) =>
                setCreateData({ ...createData, report_url: e.target.value })
              }
              defaultValue={createData.report_url}
              placeholder="Вставьте ссылку "
              className="form-control"
              name="report_url"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FileUploadFormEdit;
