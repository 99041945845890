import { createAsyncThunk } from "@reduxjs/toolkit";
import cityService from "../cityService";

// City get

export const citySearch = createAsyncThunk(
  "city/search",
  async (data, thunkAPI) => {
    try {
      return await cityService.citySearch(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//
