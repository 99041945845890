import { createAsyncThunk } from "@reduxjs/toolkit";
import applicationAdminService from "../applicationAdminService";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Chat  Get

export const applicationAdminChangeBuyer = createAsyncThunk(
  "admin/applicationAdminChangeBuyer",
  async ({ id, data }, thunkAPI) => {
    try {
      return await applicationAdminService.applicationAdminChangeBuyer(
        id,
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//
