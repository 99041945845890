import React, { useState } from "react";

function Time({ data, onSubmit, isLoading }) {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="settings__item w-100 mt-4 add-car-data__item">
      <form onSubmit={(e) => onSubmit(e, formData)}>
        <div className=" d-flex align-items-end flex-wrap  add-car-data__item">
          {/* Спец. предложение, минут */}
          <div className="row__col-4 mt-3">
            <h4>Спец. предложение, минут</h4>
            <input
              type="phone"
              className="input-style "
              placeholder="Не указано"
              name="special_time"
              defaultValue={data?.data?.settings?.special_time}
              onChange={handleChange}
            />
          </div>
          {/*  */}

          {/* Узнать наличие, месяц */}
          <div className="row__col-4 mt-3">
            <h4>Уточнить наличие, месяц</h4>
            <input
              type="phone"
              className="input-style "
              placeholder="Не указано"
              name="availability_month"
              defaultValue={data?.data?.settings?.availability_month}
              onChange={handleChange}
            />
          </div>
          {/*  */}

          {/* В процессе, минут */}
          <div className=" row__col-4 mt-3">
            <h4>В процессе, минут</h4>
            <input
              type="phone"
              className="input-style "
              placeholder="Не указано"
              name="buy_date"
              defaultValue={data?.data?.settings?.buy_date}
              onChange={handleChange}
            />
          </div>
          {/*  */}
        </div>
        <button
          type="submit"
          name="save"
          className={`gradient-btn-blue admin-btn-padding xp-3`}
        >
          {isLoading ? (
            <>
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Сохранение...</span>
            </>
          ) : (
            "Сохранить"
          )}
        </button>
      </form>
    </div>
  );
}

export default Time;
