import React from "react";

import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatNumber } from "../../utils/format-price";
import SingleGallery from "../single-gallery/single-gallery";

function SingleAuction({ single, accessories, reports, children }) {
  const formatVIN = (vin) => {
    if (vin) {
      return `${vin.slice(0, 6)}**${vin.slice(6, 8)}${vin.slice(
        10,
        12
      )}****${vin.slice(14, 16)}`;
    }
  };
  const textWithLinks = single?.car_additional
    ? single?.car_additional
        .replace(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%=~_|$?!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
          "<a href='$1' target='_blank' rel='noopener noreferrer'>$1</a>"
        )
        .replace(/\n/g, "<br />")
    : "";
  const { user } = useSelector((state) => state.profileGet);
  return (
    single && (
      <div className="row detail">
        <div className="row__col-3">
          {/* Галлерея и видео авто */}
          <SingleGallery single={single} />
          {/*  */}
        </div>
        <div className="row__col-3">
          {/* Место нахождения */}
          <div className="row__item">
            <div className="row__title-14">Место нахождения</div>
            <div className="list-key-val">
              <div className="list-key-val__item d-flex justify-content-between">
                <div className="city-point">{single?.city?.name}</div>
                <span className="text-right">
                  <Link
                    to={single?.map}
                    className="xtext-btn blue-gradient-text "
                    target="_blank"
                  >
                    Смотреть на карте
                  </Link>
                </span>
              </div>
            </div>
          </div>
          {/*  */}
          {/* Характеристики */}
          <div className="row__item">
            <div className="row__title-14">Характеристики</div>
            <div className="list-key-val">
              {single?.year && (
                <div className="list-key-val__item">
                  <span>Год выпуска:</span>
                  <span>{single?.year}</span>
                </div>
              )}

              {single?.milage && (
                <div className="list-key-val__item">
                  <span>Пробег:</span>
                  <span>{single?.milage} км</span>
                </div>
              )}

              {accessories?.carSeries && (
                <div className="list-key-val__item">
                  <span>Кузов:</span>
                  <span>{accessories?.carSeries}</span>
                </div>
              )}

              {accessories?.carModification && (
                <div className="list-key-val__item">
                  <span>Двигатель:</span>
                  <span>
                    {accessories?.carModification} / {accessories?.carEngine}
                  </span>
                </div>
              )}

              {single?.carTransmission && (
                <div className="list-key-val__item">
                  <span>Коробка передач:</span>
                  <span>{single?.carTransmission}</span>
                </div>
              )}

              {single?.carGears && (
                <div className="list-key-val__item">
                  <span>Привод:</span>
                  <span>{single?.carGears}</span>
                </div>
              )}

              <div className="list-key-val__item">
                <span>Владельцы:</span>
                <span>{single?.owner_number}</span>
              </div>

              {single?.pts && (
                <div className="list-key-val__item">
                  <span>ПТС:</span>
                  <span>{single?.pts}</span>
                </div>
              )}
            </div>
          </div>
          {/*  */}

          {/* VIN */}
          <div className="row__item">
            <div className="row__title-14">VIN номер</div>
            <div className="list-key-val">
              <div className="list-key-val__item">
                <span>{formatVIN(single?.vin)}</span>
                <span>
                  <Link
                    to={reports?.value}
                    className="text-btn blue-gradient-text "
                    target="_blank"
                  >
                    {reports?.name}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          {/*  */}

          {/* Тех. состояние */}
          <div className="row__item">
            <div className="row__title-14">Тех. состояние</div>
            <div className="list-key-val">
              {single?.cat_auto !== null && (
                <div className="list-key-val__item">
                  <span>Состояние</span>
                  <span>{single?.cat_auto}</span>
                </div>
              )}
              {single?.starts_up !== null && (
                <div className="list-key-val__item">
                  <span>Заводится</span>
                  <span>{single?.starts_up}</span>
                </div>
              )}

              {single?.condition_engine !== null && (
                <div className="list-key-val__item">
                  <span>Неисправности двигателя</span>
                  <span>{single?.condition_engine?.join(", ")}</span>
                </div>
              )}

              {single?.condition_transmission !== null && (
                <div className="list-key-val__item">
                  <span>Неисправности КПП</span>
                  <span>{single?.condition_transmission.join(", ")}</span>
                </div>
              )}

              {single?.condition_electric !== null && (
                <div className="list-key-val__item">
                  <span>Неисправности электрики</span>
                  <span>{single?.condition_electric?.join(", ")}</span>
                </div>
              )}

              {single?.condition_gear !== null && (
                <div className="list-key-val__item">
                  <span>Неисправности ходовой</span>
                  <span>{single?.condition_gear?.join(", ")}</span>
                </div>
              )}

              {single?.disks && (
                <div className="list-key-val__item">
                  <span>Диски</span>
                  <span>{single?.disks}</span>
                </div>
              )}
              {single?.tires && (
                <div className="list-key-val__item">
                  <span>Шины</span>
                  <span>{single?.tires}</span>
                </div>
              )}

              {single?.tire_wire && (
                <div className="list-key-val__item">
                  <span>Износ</span>
                  <span>{single?.tire_wire}</span>
                </div>
              )}
              {single?.additionally && (
                <div className="list-key-val__item">
                  <span>Дополнительно</span>
                  <span>{single?.additionally}</span>
                </div>
              )}
            </div>
          </div>
          {/*  */}
        </div>

        <div className="row__col-3">
          <div className="row__item">{children}</div>
          {/* История ставок*/}
          {user?.data?.role === "Seller" && single.status_id !== 1 && (
            <div className="row__item history-rate-auction">
              <div className="row__title-16">История ставок</div>
              <span>Аукцион №{single.id}</span>
              {single.greade_price &&
                single.greade_price.map((item) => {
                  return (
                    <div class="d-flex justify-content-between align-items-end logs">
                      <div>
                        <div class="logs-time">
                          {moment(item.created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </div>
                      <b>{formatNumber(item.price)} ₽</b>
                    </div>
                  );
                })}
            </div>
          )}

          {/*  */}
          {textWithLinks !== "" && (
            <div className="row__item">
              <div className="detail__text">
                <div className="row__title-14">Описание</div>

                <p
                  dangerouslySetInnerHTML={{
                    __html: textWithLinks,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default SingleAuction;
