import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import reset_img from "../../../images/reset.svg";
import { applicationsGet } from "../../../store/applications/applicationsGetSlice/applicationsGetSlice";
import { setQueryApplicationSlice } from "../../../store/query/applicationQuerySlice";
import { auto_diagnostics } from "../../../utils/lots-create-array";
import { customNoOptionsMessage } from "../../../utils/option-not-found";
import selectStyle from "../../../utils/select-styles";

function FilterAdminHeader({ user, market, setSearch }) {
  const isManagerAdmin =
    user?.data?.role === "Admin" || user?.data?.role === "Manager";

  const dispatch = useDispatch();
  const query = useSelector((state) => state.queryApplication);
  const [inputValue, setInputValue] = useState("");

  const filterData = (e) => {
    const { name, value } = e.target ? e.target : e;
    setSearch((prevQuery) => ({
      ...prevQuery, // Сохраняем предыдущие значения
      [name]: value,
    }));
  };

  const onChecked = () => {
    const newQuery = { ...query, sort: "chat" };
    dispatch(setQueryApplicationSlice(newQuery));
    dispatch(applicationsGet(newQuery));
  };

  const onFilter = (e) => {
    if (e.key === "Enter") {
      filterData(e);
      setSearch((prevQuery) => ({
        ...prevQuery, // Сохраняем предыдущие значения
        search: inputValue, // Добавляем новое значение поиска
      }));
    }
  };

  const onFilterDiagnostic = (e) => filterData(e);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const onReset = () => {
    setInputValue("");

    setSearch((prevQuery) => ({
      ...prevQuery, // Сохраняем предыдущие значения
      search: "", // Добавляем новое значение поиска
    }));
  };

  return (
    <div className="d-flex align-items-center admin__header-filter">
      <div className="admin__header-filter-search">
        <span>
          {market ? "Поиск авто" : "Поиск по номеру договора, vin , id"}
        </span>
        <input
          type="text"
          className="input-style"
          placeholder="Все"
          name="search"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={onFilter}
        />
      </div>
      {isManagerAdmin && market && (
        <>
          <div className=" mx-4">
            <span>Диагностика</span>
            <Select
              className="input-style"
              noOptionsMessage={customNoOptionsMessage}
              options={auto_diagnostics("filter_diagnostics_web")}
              styles={selectStyle}
              placeholder="Не указано"
              onChange={onFilterDiagnostic}
            />
          </div>
          <div>
            <label className="custom-radio mt-4">
              <input
                type="checkbox"
                name="sort"
                value="chat"
                onChange={onChecked}
              />
              <span></span>Непрочитанные сообщения
            </label>
          </div>
        </>
      )}

      <div className="mt-3 mx-2">
        <button type="button" className="reset-button" onClick={onReset}>
          <img src={reset_img} alt="" />
        </button>
      </div>
    </div>
  );
}

export default FilterAdminHeader;
