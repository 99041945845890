import React, { Component } from "react";
import { connect } from "react-redux"; // Подключение к Redux store
import { errorPost } from "../../store/error/errorSlice/errorSlice";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Логируем ошибку
    this.setState({ hasError: true, error, errorInfo });

    const errorData = `
      React Error Boundary: ${error.message}
      Component Stack: ${
        errorInfo?.componentStack || "No stack trace available"
      }
    `;

    // Отправляем ошибку через Redux
    this.props.dispatch(errorPost({ error: errorData.trim() }));

    console.error("Error Boundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Что-то пошло не так. Пожалуйста, попробуйте позже.</h1>;
    }
    return this.props.children;
  }
}

export default connect()(ErrorBoundary); // Подключение к Redux store
